import React from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  CompanySummaryCounterCard,
  CompanyTabPane,
  CompanyTabs,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import DirectorsContent from './DirectorsContent';
import ShareHoldersContent from './ShareHoldersContent';
import OfficersContent from './OfficersContent';

import './styles.less';
import usePeopleBusiness from '../../hooks/UsePeopleBusiness';
import RegisteredAgentsContent from './RegisteredAgentsContent';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.businessIdentity';

const BusinessIdentityComponent = ({
  businessIdentityData,
  itemsToExport,
  render = true,
  print = false,
}) => {
  const { t: translate } = useTranslation();

  const {
    directors,
    officers,
    shareholders,
    registeredAgents,
    summaryCounter,
  } = usePeopleBusiness({
    businessIdentityData,
  });

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    subTab: StringParam,
  });

  if (!render) return null;

  const subTabsAllowed = ['directors', 'shareholders', 'officers', 'registeredAgents'];

  const getActiveTab = (tab) => {
    return subTabsAllowed.includes(tab) ? tab : 'directors';
  };

  return (
    <>
      <div className="kyb-company-summary-counter-card">
        <CompanySummaryCounterCard
          payload={summaryCounter}
          render={itemsToExport?.value}
        />
      </div>
      <aside className="KYB-people-sources-sayari">
        <CompanyTabs
          className="wrapper-tabs"
          defaultActiveKey="summary"
          activeKey={getActiveTab(query?.subTab)}
          onChange={(subTab) => {
            setQuery(() => ({ subTab }));
          }}
          destroyInactiveTabPane
          print={print}
        >
          <CompanyTabPane
            render={!print || itemsToExport?.items.directors.value === true}
            print={print}
            tab={translate(`${I18N_BASE_PATH}.tabs.directors.title`, 'Directors')}
            key="directors"
          >
            <DirectorsContent directors={directors} print={print} />
          </CompanyTabPane>
          <CompanyTabPane
            render={!print || itemsToExport?.items.shareholders.value === true}
            print={print}
            tab={translate(`${I18N_BASE_PATH}.tabs.shareholders.title`, 'Shareholders')}
            key="shareholders"
          >
            <ShareHoldersContent shareholders={shareholders} print={print} />
          </CompanyTabPane>
          <CompanyTabPane
            render={!print || itemsToExport?.items.registeredAgents.value === true}
            print={print}
            tab={translate(
              `${I18N_BASE_PATH}.tabs.registeredAgents.title`,
              'Registered agents'
            )}
            key="registeredAgents"
          >
            <RegisteredAgentsContent registeredAgents={registeredAgents} print={print} />
          </CompanyTabPane>
          <CompanyTabPane
            render={!print || itemsToExport?.items.officers.value === true}
            print={print}
            tab={translate(`${I18N_BASE_PATH}.tabs.officers.title`, 'Officers')}
            key="officers"
          >
            <OfficersContent officers={officers} print={print} />
          </CompanyTabPane>
        </CompanyTabs>
      </aside>
    </>
  );
};

export default BusinessIdentityComponent;
