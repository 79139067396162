import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Typography, Row } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import FeatureFlagWrapper from 'components/FeatureFlagWrapper';

import './styles.less';

const { Text } = Typography;

const RectangleButton = ({
  title,
  description,
  icon,
  selected,
  comingSoon,
  disabled,
  orientation,
  price,
  priceExtra,
  Icon,
  ...restProps
}) => {
  const { t: translate } = useTranslation();
  const { i18nNumberToFormattedString } = useI18nFormatters();

  if (priceExtra) {
    price -= priceExtra;
  }

  return (
    <Button
      id="rectangle-button-component"
      className={`${selected && 'selected'} ${
        comingSoon && 'coming-soon'
      } ${orientation}`}
      disabled={disabled || comingSoon}
      {...restProps}
    >
      {icon && <i className={`${icon} content-icon`} />}
      {Icon && (
        <div className="content-icon">
          <Icon width={30} height={30} />
        </div>
      )}
      <div className="content-text">
        <Text>{title}</Text>
        {description && <Text>{description}</Text>}
        {comingSoon && (
          <Text className="coming-soon-tag">
            {translate('components.button.rectangleButton.text.comingSoon')}
          </Text>
        )}
        <FeatureFlagWrapper featureFlag="costPrices">
          <Row align="middle">
            {price ? (
              <Text className="coming-soon-tag">
                {i18nNumberToFormattedString(price, 'pt-BR')}
              </Text>
            ) : (
              <></>
            )}
            {priceExtra ? (
              <>
                <Text> + </Text>
                <Text className="coming-soon-tag">
                  {i18nNumberToFormattedString(priceExtra, 'pt-BR')}
                </Text>
              </>
            ) : (
              <></>
            )}
          </Row>
        </FeatureFlagWrapper>
      </div>
    </Button>
  );
};

RectangleButton.propTypes = {
  icon: PropTypes.string,
  Icon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  selected: PropTypes.bool,
  comingSoon: PropTypes.bool,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  price: PropTypes.number,
  priceExtra: PropTypes.number,
  ...Button.propTypes,
};

RectangleButton.defaultProps = {
  description: null,
  selected: false,
  comingSoon: false,
  orientation: 'vertical',
  price: null,
  priceExtra: null,
  icon: null,
  Icon: null,
};

export default RectangleButton;
