/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import defaultImage from 'assets/images/profile-default.png';

import {
  eyeAndHairTranslator,
  sanctionType,
  pepLevel,
} from '../../../utils/handlerSanctions';

import './styles.less';

const SourceInfosToPrint = ({ data, pep, others }) => {
  const { t: translate } = useTranslation();

  return (
    <div id="executions-source-infos-to-print">
      {data?.length > 0 && (
        <Row className="mrg-top-10 reset-mrg">
          {data?.map((sourceDetailItem) => (
            <Col span={24} className="information-source-data-details">
              {pep ? (
                <>
                  {others && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.others.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.source ||
                        translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.others.text'
                        )}
                    </span>
                  )}
                  {sourceDetailItem?.level && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemLevel.title'
                        )}{' '}
                      </strong>
                      {pepLevel(sourceDetailItem?.level, translate)?.description}
                    </span>
                  )}
                  {sourceDetailItem?.jobTitle &&
                    sourceDetailItem?.jobTitle !==
                      translate(
                        'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemJobTitle.value'
                      ) && (
                      <span>
                        <strong>
                          {translate(
                            'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemJobTitle.title'
                          )}{' '}
                        </strong>
                        {capitalizeFirstLetter(sourceDetailItem?.jobTitle)}
                      </span>
                    )}
                  {sourceDetailItem?.department && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemDepartment.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.department}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {others && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.others.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.source ||
                        translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.others.text'
                        )}
                    </span>
                  )}
                  {sourceDetailItem?.matchRate && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemMatchRate.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.matchRate || '100'} %
                    </span>
                  )}
                  {sourceDetailItem?.type && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemType.title'
                        )}{' '}
                      </strong>
                      {translate(sanctionType(sourceDetailItem?.type))}
                    </span>
                  )}
                  {sourceDetailItem?.sanctionName && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemSanctionName.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.sanctionName?.toUpperCase() || '-'}
                    </span>
                  )}
                  {sourceDetailItem?.description && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemDescription.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.description}
                    </span>
                  )}
                  {sourceDetailItem?.nationalities && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemNationalities.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.nationalities}
                    </span>
                  )}
                  {sourceDetailItem?.eyes && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemEyes.title'
                        )}{' '}
                      </strong>
                      {translate(eyeAndHairTranslator(sourceDetailItem?.eyes))}
                    </span>
                  )}
                  {sourceDetailItem?.hair && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemHair.title'
                        )}{' '}
                      </strong>
                      {translate(eyeAndHairTranslator(sourceDetailItem?.hair))}
                    </span>
                  )}
                  {sourceDetailItem?.considerations && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemConsiderations.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.considerations}
                    </span>
                  )}
                  {sourceDetailItem?.distinguishingMarks && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemDistinguishingMarks.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.distinguishingMarks}
                    </span>
                  )}
                  {sourceDetailItem?.department && (
                    <span>
                      <strong>
                        {translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemDepartment.title'
                        )}{' '}
                      </strong>
                      {sourceDetailItem?.department}
                    </span>
                  )}
                  {sourceDetailItem?.image && (
                    <div className="information-source-data-image">
                      <img
                        className={`${!sourceDetailItem?.image ? 'grayscale-95' : ''}`}
                        src={sourceDetailItem?.image || defaultImage}
                        alt={translate(
                          'components.dataSourceCards.restrictiveLists.components.sources.sourceInfosToPrint.index.sourceInfosToPrint.sourceDetailItemImage.title'
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          ))}
        </Row>
      )}

      {!data?.length > 0 && <></>}
    </div>
  );
};

export default SourceInfosToPrint;
