/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Checkbox } from 'antd';

import { useAuth } from 'hooks/auth';
// import CustomCheckbox from './CustomCheckbox';

const PolicyRow = ({
  title,
  icon,
  ruleName,
  formRef,
  availability = [],
  disabled,
  allowedTenants = [],
  ResourcesComponent,
}) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();

  const [otherChecks, setOtherChecks] = useState({
    create: formRef.getFieldValue([ruleName, 'create']),
    update: formRef.getFieldValue([ruleName, 'update']),
    delete: formRef.getFieldValue([ruleName, 'delete']),
  });

  const checkReadPermission = useCallback(
    (rule, type, value) => {
      setOtherChecks((oldState) => ({
        ...oldState,
        [type]: value,
      }));

      if (!value) return;
      if (formRef.getFieldValue([rule, 'read'])) return;

      formRef.setFieldsValue({ [rule]: { read: true } });
    },
    [formRef]
  );

  return (
    (allowedTenants.length === 0 || allowedTenants.includes(user.tenantId)) && (
      <div className="divider-crud">
        <Row>
          <Col
            span={ResourcesComponent ? 6 : 12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <i className={`caf-${icon} font-size-20 mrg-right-10`} />
            {translate(title)}
          </Col>

          {ResourcesComponent && <Col span={6}>{ResourcesComponent}</Col>}

          <Col span={3} className="flex center">
            <Form.Item
              name={[ruleName, 'read']}
              className="no-mrg no-pdd"
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  disabled ||
                  !availability[0] ||
                  otherChecks.create ||
                  otherChecks.update ||
                  otherChecks.delete
                }
              />
            </Form.Item>
          </Col>

          <Col span={3} className="flex center">
            <Form.Item
              name={[ruleName, 'update']}
              className="no-mrg no-pdd"
              valuePropName="checked"
            >
              <Checkbox
                disabled={disabled || !availability[1]}
                onChange={(value) =>
                  checkReadPermission(ruleName, 'update', value.target.checked)
                }
              />
            </Form.Item>
          </Col>

          <Col span={3} className="flex center">
            <Form.Item
              name={[ruleName, 'create']}
              className="no-mrg no-pdd"
              valuePropName="checked"
            >
              <Checkbox
                disabled={disabled || !availability[2]}
                onChange={(value) =>
                  checkReadPermission(ruleName, 'create', value.target.checked)
                }
              />
            </Form.Item>
          </Col>

          <Col span={3} className="flex center">
            <Form.Item
              name={[ruleName, 'delete']}
              className="no-mrg no-pdd"
              valuePropName="checked"
            >
              <Checkbox
                disabled={disabled || !availability[3]}
                onChange={(value) => {
                  checkReadPermission(ruleName, 'delete', value.target.checked);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    )
  );
};

export default PolicyRow;
