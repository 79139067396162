/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import * as executionDataSources from 'utils/executionDataSource';
import SPCRestrictionsScore from 'components/DataSourceCards/FinancialInfos/Shared/SPCRestrictionsScore';
import SPC from 'components/DataSourceCards/FinancialInfos/Shared/SPC';
import PGFN from 'components/DataSourceCards/FinancialInfos/Shared/PGFN';
import Protests from 'components/DataSourceCards/FinancialInfos/Shared/Protests';
import ZoopScore from 'components/DataSourceCards/FinancialInfos/Shared/Zoop/Score';
import PGFNRegularCertificate from 'components/DataSourceCards/FinancialInfos/Shared/PGFNRegularCertificate';
import CadinSP from './components/CadinSP';
import SPCCompanyReportParticipationScore from './components/SPCCompanyReportParticipationScore';

import AssociatedCVM from './components/AssociatedCVM';
import DebtorsPGFN from './components/DebtorsPGFN';
import CompanyOwnersPercentage from './components/CompanyOwnersPercentage';
import PenaltiesOnCVM from './components/PenaltiesOnCVM';
import CreditOperationsScr from '../Shared/CreditOperationsScr';

import DataSourceCardCollapsePanel from '../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH = 'components.dataSourceCards.financialInformation.companies';

const BusinessFinancialInformation = ({ toPrint, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const showLegacySPCInfo = useMemo(() => findSourceSection('pjSpc'), [
    findSourceSection,
  ]);

  const showReportSPC = useMemo(() => findSourceSection('pjSpcRestrictionsScore'), [
    findSourceSection,
  ]);

  const showSPCCompanyReportParticipation = useMemo(
    () => findSourceSection('pjSpcCompanyReportParticipationScore'),
    [findSourceSection]
  );

  const showCompanyOwnersPercentage = useMemo(
    () => findSourceSection('pjCompanyOwnersPercentage'),
    [findSourceSection]
  );

  const showProtest = useMemo(() => findSourceSection('pjProtesto'), [findSourceSection]);
  const showPGFN = useMemo(() => findSourceSection('pjPgfn'), [findSourceSection]);
  const PGFNRegularCertificateData = useMemo(
    () => findSourceSection('pjPgfnRegularCertificate'),
    [findSourceSection]
  );
  const showCadinSP = useMemo(() => findSourceSection('pjCadinSp'), [findSourceSection]);
  const showZoopScore = useMemo(
    () => findSourceSection('pjScoreCheck') || findSourceSection('pjBigidSerasa'),
    [findSourceSection]
  );
  const showDebtorsPgfn = useMemo(() => findSourceSection('pjPgfnDebtors'), [
    findSourceSection,
  ]);
  const showPenaltiesOnCvm = useMemo(() => findSourceSection('pjPenaltiesOnCvm'), [
    findSourceSection,
  ]);
  const creditOperationsScr = useMemo(() => findSourceSection('pjCreditOperationsScr'), [
    findSourceSection,
  ]);

  const showGeneralDossierCard = useMemo(
    () =>
      showLegacySPCInfo ||
      showCadinSP ||
      showPGFN ||
      showProtest ||
      showZoopScore ||
      PGFNRegularCertificateData ||
      showDebtorsPgfn ||
      showPenaltiesOnCvm ||
      creditOperationsScr ||
      showSPCCompanyReportParticipation ||
      showCompanyOwnersPercentage ||
      showReportSPC,
    [
      showCadinSP,
      showLegacySPCInfo,
      showPGFN,
      showProtest,
      showZoopScore,
      PGFNRegularCertificateData,
      showDebtorsPgfn,
      showPenaltiesOnCvm,
      creditOperationsScr,
      showSPCCompanyReportParticipation,
      showCompanyOwnersPercentage,
      showReportSPC,
    ]
  );

  const showAssociatedCvm = useMemo(() => findSourceSection('pjAssociatedCvm'), [
    findSourceSection,
  ]);

  return (
    <>
      {showGeneralDossierCard ? (
        <DataSourceCardCollapsePanel
          id="financial-infos-component"
          title={translate(`${I18N_BASE_PATH}.index.companies.dossierCard.title`)}
          icon="caf-ic_dollar_sign"
          toPrint={toPrint}
        >
          {showLegacySPCInfo && (
            <SPC
              source={showLegacySPCInfo}
              profile={execution}
              toPrint={toPrint}
              toPrintDetails={toPrintDetails}
            />
          )}

          {showCadinSP && (
            <CadinSP source={showCadinSP} profile={execution} toPrint={toPrint} />
          )}
          {showPGFN && (
            <PGFN
              source={showPGFN}
              execution={execution}
              type="empresa"
              toPrint={toPrint}
            />
          )}
          {PGFNRegularCertificateData && (
            <PGFNRegularCertificate
              source={PGFNRegularCertificateData}
              data={PGFNRegularCertificateData}
              toPrint={toPrint}
            />
          )}
          {showProtest && (
            <Protests source={showProtest} type="empresa" toPrint={toPrint} />
          )}
          {showZoopScore && <ZoopScore source={showZoopScore} toPrint={toPrint} />}
          {showDebtorsPgfn && <DebtorsPGFN source={showDebtorsPgfn} toPrint={toPrint} />}
          {showPenaltiesOnCvm && (
            <PenaltiesOnCVM source={showPenaltiesOnCvm} toPrint={toPrint} />
          )}
          {showCompanyOwnersPercentage && (
            <CompanyOwnersPercentage
              source={showCompanyOwnersPercentage}
              toPrint={toPrint}
            />
          )}

          {showReportSPC && (
            <SPCRestrictionsScore
              source={showReportSPC}
              toPrint={toPrint}
              toPrintDetails={toPrintDetails}
            />
          )}

          {showSPCCompanyReportParticipation && (
            <SPCCompanyReportParticipationScore
              source={showSPCCompanyReportParticipation}
              toPrint={toPrint}
              toPrintDetails={toPrintDetails}
            />
          )}

          {showAssociatedCvm && (
            <AssociatedCVM
              source={showAssociatedCvm}
              toPrint={toPrint}
              toPrintDetails={toPrintDetails}
            />
          )}
          {creditOperationsScr && (
            <CreditOperationsScr
              source={creditOperationsScr}
              toPrint={toPrint}
              type="PJ"
            />
          )}
        </DataSourceCardCollapsePanel>
      ) : (
        <></>
      )}
    </>
  );
};

export default BusinessFinancialInformation;
