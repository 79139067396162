/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import * as executionDataSources from 'utils/executionDataSource';
import PersonalFinance from './components/PersonalFinance';
import JointDebts from './components/JointDebts';
import SocialAssistance from './components/SocialAssistance';
import UnemploymentInsurance from './components/UnemploymentInsurance';

import SPC from '../Shared/SPC';
import PGFN from '../Shared/PGFN';
import Protests from '../Shared/Protests';
import ZoopScore from '../Shared/Zoop/Score';
import SPCRestrictionsScore from '../Shared/SPCRestrictionsScore';
import BacenQgi from '../Shared/BacenQgi';
import AssociatedCVM from './components/AssociatedCVM';
import DebtorsPGFN from './components/DebtorsPGFN';
import PGFNRegularCertificate from '../Shared/PGFNRegularCertificate';
import PenaltiesOnCVM from './components/PenaltiesOnCVM';
import MbsMs2 from './components/mbsMs2';
import CreditOperationsScr from '../Shared/CreditOperationsScr';
import PfEstimatedIncome from './components/pfEstimatedIncome';
import PfIbopeIncome from './components/pfIbopeIncome';

import DataSourceCardCollapsePanel from '../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH = 'components.dataSourceCards.financialInformation.people';

const PersonalFinancialInformation = ({ toPrint = false, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  // const urlId = useMemo(() => data?.recurrenceModel?._id, [data]);

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const showPersonalFinance = useMemo(
    () =>
      findSourceSection('pfFinantialData') ||
      findSourceSection('pfSalesbox') ||
      findSourceSection('pfSpcIncome') ||
      findSourceSection('pfCollections') ||
      findSourceSection('incomeEstimates') ||
      findSourceSection('totalAssets'),
    [findSourceSection]
  );

  const pfEstimatedIncome = useMemo(() => findSourceSection('pfEstimatedIncome'), [
    findSourceSection,
  ]);
  const pfIbopeIncome = useMemo(() => findSourceSection('pfIbopeIncome'), [
    findSourceSection,
  ]);
  const showLegacySPCInfo = useMemo(
    () => findSourceSection('pfSpc') || findSourceSection('pfSpcIncome'),
    [findSourceSection]
  );

  const showProtest = useMemo(() => findSourceSection('pfProtesto'), [findSourceSection]);

  const showPGFN = useMemo(() => findSourceSection('pfPgfn'), [findSourceSection]);
  const PGFNRegularCertificateData = useMemo(
    () => findSourceSection('pfPgfnRegularCertificate'),
    [findSourceSection]
  );
  const showJointDebts = useMemo(() => findSourceSection('pfJointDebtsCertificate'), [
    findSourceSection,
  ]);

  const showSocialAssistance = useMemo(() => findSourceSection('pfSocialAssistance'), [
    findSourceSection,
  ]);

  const showUnemploymentInsurance = useMemo(
    () => findSourceSection('pfSeguroDesemprego'),
    [findSourceSection]
  );

  const associatedCVM = useMemo(() => findSourceSection('pfAssociatedCvm'), [
    findSourceSection,
  ]);

  const debtorsPgfn = useMemo(() => findSourceSection('pfPgfnDebtors'), [
    findSourceSection,
  ]);

  const showZoopScore = useMemo(
    () => findSourceSection('pfScoreCheck') || findSourceSection('pfBigidSerasa'),
    [findSourceSection]
  );

  const showPenaltiesOnCvm = useMemo(() => findSourceSection('pfPenaltiesOnCvm'), [
    findSourceSection,
  ]);

  const showMbsMs2 = useMemo(() => findSourceSection('pfMbsArcaMs2'), [
    findSourceSection,
  ]);

  const creditOperationsScr = useMemo(() => findSourceSection('pfCreditOperationsScr'), [
    findSourceSection,
  ]);

  const showReportSPCCard = useMemo(() => findSourceSection('pfSpcRestrictionsScore'), [
    findSourceSection,
  ]);

  const showBacenQgi = useMemo(() => findSourceSection('pfBacenQgi'), [
    findSourceSection,
  ]);

  const showFinancialInfoCard = useMemo(() => {
    return (
      showPersonalFinance ||
      showLegacySPCInfo ||
      showProtest ||
      showPGFN ||
      PGFNRegularCertificateData ||
      showJointDebts ||
      showSocialAssistance ||
      showUnemploymentInsurance ||
      showZoopScore ||
      debtorsPgfn ||
      showPenaltiesOnCvm ||
      showMbsMs2 ||
      creditOperationsScr ||
      pfEstimatedIncome ||
      pfIbopeIncome ||
      showBacenQgi ||
      showReportSPCCard
    );
  }, [
    showJointDebts,
    showLegacySPCInfo,
    showPGFN,
    showPersonalFinance,
    showProtest,
    showSocialAssistance,
    showUnemploymentInsurance,
    showZoopScore,
    PGFNRegularCertificateData,
    debtorsPgfn,
    showPenaltiesOnCvm,
    showMbsMs2,
    creditOperationsScr,
    pfEstimatedIncome,
    pfIbopeIncome,
    showBacenQgi,
    showReportSPCCard,
  ]);

  return (
    <>
      {showFinancialInfoCard ? (
        <DataSourceCardCollapsePanel
          id="financial-infos-component"
          title={translate(`${I18N_BASE_PATH}.index.people.dossierCard.title`)}
          icon="caf-ic_dollar_sign"
          toPrint={toPrint}
        >
          {showPersonalFinance && <PersonalFinance toPrint={toPrint} />}
          {pfEstimatedIncome && (
            <PfEstimatedIncome source={pfEstimatedIncome} toPrint={toPrint} />
          )}
          {pfIbopeIncome && <PfIbopeIncome source={pfIbopeIncome} toPrint={toPrint} />}

          {showLegacySPCInfo && (
            <Row>
              <SPC
                source={showLegacySPCInfo}
                toPrint={toPrint}
                toPrintDetails={toPrintDetails}
              />
            </Row>
          )}

          {showProtest && (
            <Protests type="pessoa" source={showProtest} toPrint={toPrint} />
          )}
          {showPGFN && <PGFN source={showPGFN} type="pessoa" toPrint={toPrint} />}
          {PGFNRegularCertificateData && (
            <PGFNRegularCertificate data={PGFNRegularCertificateData} toPrint={toPrint} />
          )}
          {showJointDebts && <JointDebts source={showJointDebts} toPrint={toPrint} />}
          {showSocialAssistance && (
            <SocialAssistance source={showSocialAssistance} toPrint={toPrint} />
          )}
          {showUnemploymentInsurance && (
            <UnemploymentInsurance source={showUnemploymentInsurance} toPrint={toPrint} />
          )}
          {showZoopScore && <ZoopScore source={showZoopScore} toPrint={toPrint} />}
          {debtorsPgfn && <DebtorsPGFN source={debtorsPgfn} toPrint={toPrint} />}
          {showPenaltiesOnCvm && (
            <PenaltiesOnCVM source={showPenaltiesOnCvm} toPrint={toPrint} />
          )}
          {associatedCVM && <AssociatedCVM source={associatedCVM} toPrint={toPrint} />}
          {showReportSPCCard && (
            <SPCRestrictionsScore
              source={showReportSPCCard}
              toPrint={toPrint}
              toPrintDetails={toPrintDetails}
            />
          )}
          {showBacenQgi && (
            <BacenQgi
              source={showBacenQgi}
              toPrint={toPrint}
              toPrintDetails={toPrintDetails}
            />
          )}
          {creditOperationsScr && (
            <CreditOperationsScr
              source={creditOperationsScr}
              toPrint={toPrint}
              type="PF"
            />
          )}
          {showMbsMs2 && <MbsMs2 source={showMbsMs2} toPrint={toPrint} />}
        </DataSourceCardCollapsePanel>
      ) : (
        <></>
      )}
    </>
  );
};

export default PersonalFinancialInformation;
