import { Card, Col, Image, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { toMaskedCpf } from 'utils/formatters';

import './styles.less';

const I18N_BASEPATH =
  'pages.private.faceAuthenticator.components.list.components.faceAuthenticatorCard';

const FaceAuthenticatorCard = ({ data }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const iconClassName = data.isMatch ? 'caf-ic_checkmark' : 'caf-ic_close';
  const imageComponent = (media) => {
    return (
      <>
        <Image
          className="card-image mrg-right-25"
          width="200px"
          height="200px"
          src={media}
          alt={data._id}
          classHover
        />
      </>
    );
  };
  const videoComponent = (media) => {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <video
        className="card-image"
        width="200"
        height="200"
        autoPlay
        muted
        onMouseOver={(event) => event.target.play()}
        onMouseOut={(event) => event.target.pause()}
      >
        <source src={media} type="video/mp4" />
      </video>
    );
  };
  const mediaComponent = (media) => {
    const contentUrl = media;
    const mp4Extension = '.mp4';
    if (contentUrl.includes(mp4Extension)) {
      return videoComponent(media);
    }
    return imageComponent(media);
  };

  return (
    <Card className="face-authenticator-card" type="inner">
      {data?.expectedImage && mediaComponent(data?.expectedImage)}
      {data?.receivedImage && mediaComponent(data?.receivedImage)}
      <div className="card-info">
        <div className="card-info-status">
          <div className="icon-status-container">
            <i className={iconClassName} />
          </div>
          <div className="card-info-status-content">
            <Tooltip placement="top" title={translate(`${I18N_BASEPATH}.tooltip`)}>
              <span className="text-bold">
                {data?.isMatch ? (
                  translate(`${I18N_BASEPATH}.approved`)
                ) : (
                  <>
                    {translate(`${I18N_BASEPATH}.disapproved`)}{' '}
                    {data?.maxAttempts?.blocked && translate(`${I18N_BASEPATH}.blocked`)}
                  </>
                )}
              </span>
            </Tooltip>
            <span className="font-size-12">{`${translate(
              `${I18N_BASEPATH}.validatedAt`
            )} ${i18nFormatDate(data?.createdAt, 4)}`}</span>
          </div>
        </div>
        <div className="card-info-simple">
          <Col span={14}>
            <span>{translate(`${I18N_BASEPATH}.cpf`)}:</span>
            <span className={`pdd-left-5 ${data?.peopleId ? 'text-bold' : ''}`}>
              {toMaskedCpf(data?.peopleId) || translate(`${I18N_BASEPATH}.noCpf`)}
            </span>
          </Col>
          <Col span={10}>
            <span>{translate(`${I18N_BASEPATH}.ip`)}:</span>
            <span className="pdd-left-5 text-bold">{data?.sourceIp}</span>
          </Col>
        </div>
        <div className="card-info-simple">
          <Col span={14}>
            <span>{translate(`${I18N_BASEPATH}.similarity`)}:</span>
            <span className="pdd-left-5 text-bold">
              {Number(data?.faceMatch?.similarity * 100).toFixed(2)}%
            </span>
          </Col>
          {data?.source !== 'iproov' && (
            <Col span={10}>
              <span>{translate(`${I18N_BASEPATH}.livenessProbability`)}:</span>
              <span className="pdd-left-5 text-bold">
                {Number(
                  data?.liveness?.averageProbability * 100 ||
                    data?.liveness?.probability * 100
                ).toFixed(2)}
                %
              </span>
            </Col>
          )}
        </div>
        {data?.liveness?.openEyesProbability && data?.source !== 'iproov' && (
          <div className="card-info-simple">
            <Col span={16}>
              <span>{translate(`${I18N_BASEPATH}.openEyesProbability`)}:</span>
              <span className="pdd-left-5 text-bold">
                {Number(data?.liveness?.openEyesProbability * 100).toFixed(2)}%
              </span>
            </Col>
          </div>
        )}
        <div className="card-info-simple" />
      </div>
    </Card>
  );
};

FaceAuthenticatorCard.propTypes = {
  data: PropTypes.node.isRequired,
};

export default FaceAuthenticatorCard;
