import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Divider, Spin, message } from 'antd';

import { useReasons } from 'hooks/reasons';

import { useFetch } from 'services/hooks';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import { useCompany } from 'hooks/company';
import { usePerson } from 'hooks/person';

import './styles.less';

import CardReview from './components/CardReview';

const I18N_BASE_PATH =
  'pages.private.executions.components.generalStatus.pendingStatusModal.index';

const I18N_PENDING_REVIEW_BASE_PATH =
  'pages.private.profiles.components.profileValidations.components.pendingReviewModal.index';

const ModalManualReview = ({ data }) => {
  const { t: translate } = useTranslation();
  const { loadReasons } = useReasons();

  const [form] = Form.useForm();
  const { loading, post } = useFetch();
  const [modalVisible, setModalVisible] = useState(false);

  const { refreshCompany } = useCompany();
  const { refreshPerson } = usePerson();

  const closeModal = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const parseReasons = useCallback(
    async ({ customStatusReasons, type }) => {
      if (!customStatusReasons) return undefined;

      const reasonsList = await loadReasons({ type });

      const customReasonsIds = customStatusReasons.map((csr) => csr.key);

      const parsedStatus = reasonsList?.filter((r) => customReasonsIds.includes(r._id));

      return parsedStatus;
    },
    [loadReasons]
  );

  const onApproveReport = useCallback(
    async (reason, customStatusReasons) => {
      const parsedReasons = await parseReasons({ customStatusReasons, type: 'approval' });

      try {
        await post({
          url: `profile-manual-revision`,
          payload: {
            newStatus: 'APPROVED',
            profileId: data._id,
            profileType: data?.cpf ? 'PF' : 'PJ',
            reason: reason || parsedReasons[0]?.title,
            customStatusReasons: parsedReasons,
          },
        });

        message.success(
          translate(`${I18N_PENDING_REVIEW_BASE_PATH}.onApproveReport.messages.success`)
        );

        closeModal();
      } catch (error) {
        closeModal();
      }

      if (data?.cpf) {
        refreshPerson();
      } else {
        refreshCompany();
      }
    },
    [
      parseReasons,
      data.cpf,
      data._id,
      post,
      translate,
      closeModal,
      refreshPerson,
      refreshCompany,
    ]
  );

  const onDisapproveReport = useCallback(
    async (reason, customStatusReasons) => {
      const parsedReasons = await parseReasons({ customStatusReasons, type: 'reproval' });

      const baseurl = data?.cpf ? 'companies' : 'people';

      const payload = {
        newStatus: 'REPROVED',
        profileId: data._id,
        profileType: data?.cpf ? 'PF' : 'PJ',
        reason: reason || parsedReasons[0]?.title,
        customStatusReasons: parsedReasons,
      };

      try {
        await post({
          url: `/profile-manual-revision`,
          payload,
        });

        message.success(
          translate(
            `${I18N_PENDING_REVIEW_BASE_PATH}.onDisapproveReport.messages.success`
          )
        );

        closeModal();
      } catch (error) {
        closeModal();
      }

      if (data?.cpf) {
        refreshPerson();
      } else {
        refreshCompany();
      }
    },
    [
      parseReasons,
      data.cpf,
      data._id,
      post,
      translate,
      closeModal,
      refreshPerson,
      refreshCompany,
    ]
  );

  const handleSubmit = useCallback(
    async (value) => {
      const {
        validationReason,
        validationStatus,
        customStatusReasons,
        ...attributes
      } = value;
      const validStatus = ['APPROVED', 'REPROVED', 'REPROCESS'];

      if (validStatus.includes(validationStatus)) {
        let modalContent = null;

        if (validationStatus === 'APPROVED') {
          modalContent = translate(
            `pages.private.globalProfiles.components.generalStatus.pendingStatusModal.index.pendingStatusModal.confirmModal.aprove`
          );
        }

        if (validationStatus === 'REPROVED') {
          modalContent = translate(
            `pages.private.globalProfiles.components.generalStatus.pendingStatusModal.index.pendingStatusModal.confirmModal.reprove`
          );
        }

        if (modalContent) {
          Modal.confirm({
            centered: true,
            width: 550,
            title: modalContent,
            okText: translate(`${I18N_BASE_PATH}.pendingStatusModal.confirmModal.okText`),
            onOk() {
              if (validationStatus === 'APPROVED') {
                onApproveReport(validationReason, customStatusReasons);
              }

              if (validationStatus === 'REPROVED') {
                onDisapproveReport(validationReason, customStatusReasons);
              }
            },
            onCancel() {},
          });
        }
      }
    },
    [onApproveReport, onDisapproveReport, translate]
  );

  const ManualReviewModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        width={850}
        footer={null}
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        visible={modalVisible}
      >
        <div id="pending-review-modal-content">
          <PageTitle
            title={translate(
              `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.pageTitle.title`
            )}
            subtitle={translate(
              `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.pageTitle.subtitle`
            )}
          />

          <Form
            id="pending-review-modal-form"
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
          >
            {loading ? (
              <Spin className="flex center gx-w-100 mrg-top-30 mrg-btm-40" />
            ) : (
              <>
                <div className="pending-review-items">
                  <CardReview formRef={form} isOpenModal={modalVisible} profile={data} />
                  <Divider />
                </div>

                <div className="gx-flex-row gx-justify-content-end mrg-top-40">
                  <Button type="default" className="btn-cancel" onClick={closeModal}>
                    {translate(
                      `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.form.button.cancel`
                    )}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="apply-changes-button"
                  >
                    {translate(
                      `${I18N_PENDING_REVIEW_BASE_PATH}.pendingReviewModal.form.button.submit`
                    )}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal>
    ),
    [closeModal, data, form, loading, modalVisible, translate, handleSubmit]
  );

  return { openModal, closeModal, ManualReviewModal };
};

export default ModalManualReview;
