import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin } from 'antd';
import PropTypes from 'prop-types';

import { isEmpty } from 'utils/isObjectEmpty';

import DossierCard from 'pages/private/Profiles/components/DossierCard';

import ZoomedValidationsImagesCard from 'components/ZoomedValidationsImagesCard';
import { useExecution } from 'hooks/execution';

import EmptyDocuments from '../Shared/EmptyDocuments';
import DocumentLivenessInfos from './components/DocumentLivenessInfos';

const I18N_BASE_PATH =
  'pages.private.profiles.components.tabs.components.documentsValidations.components';

const DocumentLivenessCard = ({ loading }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();

  const noData = useMemo(
    () =>
      !execution ||
      (isEmpty(execution?.images) && isEmpty(execution?.sections?.facematch)),
    [execution]
  );

  return (
    <DossierCard
      title={translate(`${I18N_BASE_PATH}.verifiedIdentity.index.documentLiveness.title`)}
      subtitle={translate(
        `${I18N_BASE_PATH}.verifiedIdentity.index.documentLiveness.subtitle`
      )}
      icon="caf-ic_user_active"
      customId="verified-document-card"
    >
      {loading && (
        <div className="custom-document-loading">
          <Spin />
        </div>
      )}

      <>
        {!loading && noData ? (
          <EmptyDocuments type="verified-identity" />
        ) : (
          <>
            <ZoomedValidationsImagesCard
              firstImageDescription={translate(
                `${I18N_BASE_PATH}.verifiedIdentity.index.documentLiveness.images.front`
              )}
              firstImage={execution?.images?.front}
              secondImageDescription={translate(
                `${I18N_BASE_PATH}.verifiedIdentity.index.documentLiveness.images.back`
              )}
              secondImage={execution?.images?.back}
              customTop="0 !important"
            />

            <Row>
              <Col span={12}>
                <Row className="custom-validations-title reset-mrg mrg-top-10 mrg-btm-10">
                  <strong>
                    {translate(
                      `${I18N_BASE_PATH}.verifiedIdentity.index.documentLiveness.columns.front`
                    )}
                  </strong>
                </Row>
                {execution?.sections?.documentLiveness?.data?.front ? (
                  <DocumentLivenessInfos
                    information={execution?.sections?.documentLiveness?.data?.front}
                  />
                ) : (
                  translate(
                    `${I18N_BASE_PATH}.verifiedIdentity.index.documentLiveness.message`
                  )
                )}
              </Col>

              <Col span={12}>
                <Row className="custom-validations-title reset-mrg mrg-top-10 mrg-btm-10">
                  <strong>
                    {translate(
                      `${I18N_BASE_PATH}.verifiedIdentity.index.documentLiveness.columns.back`
                    )}
                  </strong>
                </Row>
                {execution?.sections?.documentLiveness?.data?.back ? (
                  <DocumentLivenessInfos
                    information={execution?.sections?.documentLiveness?.data?.back}
                  />
                ) : (
                  translate(
                    `${I18N_BASE_PATH}.verifiedIdentity.index.documentLiveness.message`
                  )
                )}
              </Col>
            </Row>
          </>
        )}
      </>
    </DossierCard>
  );
};

DocumentLivenessCard.propTypes = {
  loading: PropTypes.bool,
};

DocumentLivenessCard.defaultProps = {
  loading: false,
};

export default DocumentLivenessCard;
