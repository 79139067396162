import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useCompany } from 'hooks/company';
import { usePerson } from 'hooks/person';

import Countdown from 'components/Countdown';

import { generalStatus } from 'utils/handlerStatus';

import PermissionWrapper from 'routes/PermissionWrapper';
import useManualReviewModal from './ModalManualReview/index';

import './styles.less';

const validStatus = [
  'APPROVED',
  'APROVADO',
  'REPROVED',
  'REPROVADO',
  'PENDING',
  'PENDENTE',
  'PROCESSING',
  'PROCESSANDO',
  'WAITING_DOCUMENTS',
  'AGUARDANDO_DOCUMENTACAO',
];

// const { Countdown } = Statistic;
const I18N_BASE_PATH = 'pages.private.profiles.components.generalStatus.index';

const GeneralStatus = ({ status, profile }) => {
  const { t: translate } = useTranslation();
  const { personId } = useParams();

  const { refreshCompany } = useCompany();
  const { refreshPerson } = usePerson();

  const PENDING_STATUS = useMemo(() => ['PENDING'], []);

  const { ManualReviewModal, openModal } = useManualReviewModal({
    data: profile,
  });

  const showPendingReviewButton = useMemo(
    () => PENDING_STATUS?.includes(status?.toUpperCase()),
    [PENDING_STATUS, status]
  );

  const statusDescription = useMemo(() => {
    if (status === 'APPROVED' && profile?.approveReasons?.length > 0) {
      return (
        <ol>
          {profile?.approveReasons?.map((reason) => (
            <li style={{ marginTop: 4 }} key={reason}>
              {reason}
            </li>
          ))}
        </ol>
      );
    }
    if (status === 'REPROVED' && profile?.reproveReasons?.length > 0) {
      return (
        <ol>
          {profile?.reproveReasons?.map((reason) => (
            <li style={{ marginTop: 4 }} key={reason}>
              {reason}
            </li>
          ))}
        </ol>
      );
    }
  }, [profile.approveReasons, profile.reproveReasons, status]);

  const refreshContent = useCallback(() => {
    if (personId) {
      refreshPerson();
      return;
    }

    refreshCompany();
  }, [personId, refreshCompany, refreshPerson]);

  return (
    <>
      {ManualReviewModal}
      {translate(generalStatus(status)?.value) && (
        <div
          id="general-status-card"
          hidden={!validStatus.includes(status)}
          style={{
            backgroundColor: generalStatus(status)?.color,
          }}
        >
          <div className="general-status-info">
            <i className={`${generalStatus(status)?.icon} general-status-icon`} />
            <div className="general-status-description">
              <strong>{translate(generalStatus(status)?.display)}</strong>
              <span>{translate(generalStatus(status)?.description)}</span>
              {statusDescription}
              {status === 'PROCESSING' && (
                <Countdown
                  prefix={`${I18N_BASE_PATH}.processingCountdown.prefix`}
                  suffix={`${I18N_BASE_PATH}.processingCountdown.suffix`}
                  onFinish={refreshContent}
                />
              )}
            </div>
          </div>
          <PermissionWrapper requiredPermissions={[`profiles:update`]}>
            {showPendingReviewButton && (
              <div className="general-status-actions">
                <button
                  type="button"
                  className="ant-btn-text"
                  onClick={() => openModal({ allowReprocess: false })}
                >
                  {translate(
                    `${I18N_BASE_PATH}.missingInfos.pendingManualReview.reviewButtonText`
                  )}
                </button>
              </div>
            )}
          </PermissionWrapper>
        </div>
      )}
    </>
  );
};

GeneralStatus.propTypes = {
  status: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
};

export default GeneralStatus;
