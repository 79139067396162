/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Tag } from 'antd';

import PermissionWrapper from 'routes/PermissionWrapper';

import './styles.less';

const I18N = 'pages.private.users.components.usersList.components.switchRenderer';

const SwitchRenderer = ({ data, openModal }) => {
  const { t: translate } = useTranslation();

  const { deletedAt } = data;

  return (
    <div
      className="switch-renderer"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100px',
      }}
    >
      <PermissionWrapper
        forbiddenContent={
          <Tag className={!deletedAt ? 'tag-active' : 'tag-inactive'}>
            {translate(`${!deletedAt ? `${I18N}.tag.active` : `${I18N}.tag.inactive`}`)}
          </Tag>
        }
        product="management"
        requiredPermissions={['users-pdv:delete']}
      >
        <Switch
          style={{
            minWidth: 42,
            height: 23,
            minHeight: 23,
          }}
          checked={!deletedAt}
          onChange={() => openModal(data)}
        />
      </PermissionWrapper>
    </div>
  );
};

export default SwitchRenderer;
