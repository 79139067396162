import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQueryParams, StringParam, withDefault, ArrayParam } from 'use-query-params';
import _ from 'lodash';

// import TagsFilter from 'components/Filters/TagsFilter';

import SearchFilter from 'components/Filters/SearchFilter';
import ResetFilters from 'components/Filters/ResetFilters';
// import ReportsFilter from 'components/Filters/ReportsFilter';
import ExecutionStatusFilter from 'components/Filters/ExecutionStatusFilter';
import DocumentoscopyFilter from 'components/Filters/DocumentoscopyFilter';
import ExecutionFraudFilter from 'components/Filters/ExecutionFraudFilter';

// ! FILTRO REMOVIDO TEMPORARIAMENTE POR NÃO ESTAR FUNCIONANDO NO OPEN SEARCH 86a1h6zeb
// import RecurrenceFilter from 'components/Filters/RecurrenceFilter';
import ValidationsFilter from 'components/Filters/ValidationsFilter';
import CustomStatusFilter from 'components/Filters/CustomStatusFilter';
import ExecutionProfileTemplatesFilter from 'components/Filters/ExecutionProfileTemplatesFilter';

import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useAuth } from 'hooks/auth';
import CreatedAtFilter from './CreatedAtFilter';
import UpdatedAtFilter from './UpdatedAtFilter';

import './styles.less';

const Filter = ({ matchPeople }) => {
  const { user } = useAuth();

  const { t: translate } = useTranslation();

  const [query, setQuery] = useQueryParams({
    updatedDate: StringParam,
    createdDate: StringParam,
    search: withDefault(ArrayParam, []),
    templateIds: withDefault(ArrayParam, []),
    reports: withDefault(ArrayParam, []),
    customStatus: withDefault(ArrayParam, []),
    tags: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
    documentscopy: withDefault(ArrayParam, []),
    validations: withDefault(ArrayParam, []),
    fraud: StringParam,
    recurrence: StringParam,
    _order: StringParam,
    _sort: StringParam,
    _internalRestrictiveList: StringParam,
  });

  const setFilter = useCallback(
    (filterName, event) => {
      let queryParams = {
        [filterName]: event && !_.isEmpty(event) ? event : undefined,
      };

      if (
        (filterName === 'createdDate' || filterName === 'updatedDate') &&
        Array.isArray(event)
      ) {
        const startDate = event[0]
          .utcOffset(0)
          .set({ second: 0, millisecond: 0 })
          .valueOf();
        const endDate = event[1]
          .utcOffset(0)
          .set({ second: 59, millisecond: 0 })
          .valueOf();

        queryParams = {
          [filterName]: `custom:${startDate}-${endDate}`,
        };
      }

      if (filterName === 'templateIds') {
        const templateIds = [];
        const reports = [];

        event.forEach((e) => {
          const [template, report] = e.split('|');
          templateIds.push(template);
          reports.push(report);
        });

        queryParams = {
          templateIds: templateIds?.filter((r) => r !== 'null'),
          reports: reports?.filter((r) => r !== 'null'),
        };
      }

      setQuery(queryParams);
    },
    [setQuery]
  );

  const resetFilters = useCallback(() => {
    setQuery({
      updatedDate: undefined,
      createdDate: 'lastYear',
      search: undefined,
      tags: undefined,
      status: undefined,
      documentscopy: undefined,
      validations: undefined,
      fraud: undefined,
      reports: undefined,
      recurrence: undefined,
      customStatus: undefined,
      _internalRestrictiveList: undefined,
      templateIds: undefined,
    });
  }, [setQuery]);

  return (
    <div id="execution-filter-component">
      <SearchFilter
        placeholder={translate(
          `pages.private.executions.components.list.components.filter.index.filter.searchFilter.placeholder`
        )}
        value={query.search}
        className="profile-select"
        onChange={(values) => setFilter('search', values)}
      />

      <CreatedAtFilter
        title={translate(
          'pages.private.executions.components.list.components.filter.index.filter.createdAtFilter.title'
        )}
        value={query.createdDate}
        onChange={(values) => setFilter('createdDate', values)}
      />

      <UpdatedAtFilter
        title={translate(
          'pages.private.executions.components.list.components.filter.index.filter.updatedAtFilter.title'
        )}
        value={query.updatedDate}
        onChange={(values) => setFilter('updatedDate', values)}
      />

      {!hasFeatureFlag('isWorkflowBuilder', user) && (
        <ExecutionProfileTemplatesFilter
          value={[...query.templateIds, ...query.reports]}
          title={translate(
            'pages.private.executions.components.list.components.filter.index.filter.executionProfileTemplatesFilter.title'
          )}
          onChange={(values) => setFilter('templateIds', values)}
        />
      )}

      {/* <ReportsFilter
        title={translate(
          'pages.private.executions.components.list.components.filter.index.filter.reportsFilter.title'
        )}
        value={query.reports}
        onChange={(values) => setFilter('reports', values)}
      /> */}

      <ExecutionStatusFilter
        value={query.status}
        onChange={(values) => setFilter('status', values)}
        matchPeople={matchPeople}
      />

      {hasFeatureFlag('isWorkflowBuilder', user) && (
        <CustomStatusFilter
          title={translate(
            'pages.private.executions.components.list.components.filter.index.filter.customStatusFilter.title'
          )}
          value={query.customStatus}
          onChange={(values) => setFilter('customStatus', values)}
        />
      )}

      {hasFeatureFlag('isWorkflowBuilder', user) && (
        <ExecutionFraudFilter
          title={translate(
            'pages.private.executions.components.list.components.filter.index.filter.fraudFilter.title'
          )}
          value={query.fraud}
          onChange={(values) => setFilter('fraud', values)}
        />
      )}

      {/* // ! FILTRO REMOVIDO TEMPORARIAMENTE POR NÃO ESTAR FUNCIONANDO NO OPEN SEARCH 86a1h6zeb */}

      {/* {!hasFeatureFlag('isWorkflowBuilder', user) && (
        <RecurrenceFilter
          title={translate(
            'pages.private.executions.components.list.components.filter.index.filter.recurrenceFilter.title'
          )}
          value={query.recurrence}
          onChange={(values) => setFilter('recurrence', values)}
        />
      )} */}

      {hasFeatureFlag('isWorkflowBuilder', user) && (
        <DocumentoscopyFilter
          title={translate(
            'pages.private.executions.components.list.components.filter.index.filter.documentoscopyFilter.title'
          )}
          value={query.documentscopy}
          onChange={(values) => setFilter('documentscopy', values)}
        />
      )}

      {!hasFeatureFlag('isWorkflowBuilder', user) && (
        <ValidationsFilter
          value={query.validations}
          onChange={(values) => setFilter('validations', values)}
        />
      )}

      <ResetFilters onClick={resetFilters} />
    </div>
  );
};

Filter.propTypes = {
  matchPeople: PropTypes.bool.isRequired,
};

export default Filter;
