/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Summary from 'assets/images/company/summary.svg';
import TabTitle from 'pages/private/KYBCompany/CompanyDetails/components/TabTitle';
import { CompanyBody1, CompanyName } from '../../atoms';
import { CompanySupplierGroupButtons } from '../../molecules';
import { CompanyBusinessIdentity, CompanyCreditReport } from '../../organisms';
import './styles.less';

const PAGE = 'pages.private.KYBCompany.pages';
const BASE = `${PAGE}.companyDetails`;
const I18N = `${BASE}.subPages.companyInfo`;
const NAME = `${I18N}.subTitle`;

const CompanyInfoTabs = ({ payload, tab, itemsToExport, print = false }) => {
  if (print) {
    return (
      <>
        <CompanyCreditReport
          payload={payload}
          print={print}
          itemsToExport={itemsToExport.items.creditReport}
        />
        <CompanyBusinessIdentity
          payload={payload}
          print={print}
          itemsToExport={itemsToExport.items.businessIdentity}
        />
      </>
    );
  }

  return tab === 'credit-report' ? (
    <CompanyCreditReport payload={payload} itemsToExport={itemsToExport} />
  ) : (
    <CompanyBusinessIdentity payload={payload} />
  );
};

const CompanyInfo = ({ payload, itemsToExport, print = false, ...rest }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState('credit-report');
  if (itemsToExport?.value === false) return null;
  return (
    <div className={classnames('kyb-company-info-main', rest.className)}>
      <TabTitle
        icon={Summary}
        title={t(`${BASE}.tabs.companyInfo`, 'Company info')}
        subtitle={<CompanyName i18n={NAME} name={payload.companyName} />}
        render={!print}
      />
      <div className="kyb-company-info-content">
        <CompanyBody1 className="kyb-company-info-title-buttons" render={!print}>
          <b>{t(`${I18N}.chooseSource`, 'Choose source:')}</b>
        </CompanyBody1>
        <CompanySupplierGroupButtons value={tab} onChange={setTab} render={!print} />
        <CompanyInfoTabs
          payload={payload}
          tab={tab}
          print={print}
          itemsToExport={itemsToExport}
        />
      </div>
    </div>
  );
};

CompanyInfo.propTypes = {
  payload: PropTypes.object.isRequired,
  print: PropTypes.bool.isRequired,
  itemsToExport: PropTypes.object.isRequired,
};
CompanyInfoTabs.propTypes = CompanyInfo.propTypes;

export default CompanyInfo;
