import React, { useState } from 'react';
import { Col, Button, Radio, Popover, Collapse, Row, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import NotAvailable from 'components/NotAvailable';
import { ThumpsUp, ThumpsUp1, Less } from '@combateafraude/icons/general';
import {
  CompanyTag,
  CompanySubtitle2,
  CompanyBody1,
  CompanyHeadlineSmall,
  CompanyCaption,
  CompanySubtitle1,
} from './atoms';

const STATUS_PATH =
  'pages.private.KYBCompany.pages.companySearching.components.consultedCompanies.companyCard';

const { TabPane } = Tabs;

export const CompanyTabs = ({ children, print = false, ...rest }) => {
  if (print) {
    return <div className="kyb-company-tabs">{children}</div>;
  }

  return <Tabs {...rest}>{children}</Tabs>;
};

export const CompanyTabPane = ({
  children,
  tab,
  print = false,
  render = true,
  ...rest
}) => {
  if (!render) return null;

  if (print) {
    return (
      <div className="kyb-company-tab-pane">
        <CompanySubtitle1>{tab}</CompanySubtitle1>
        {children}
      </div>
    );
  }

  return <TabPane {...rest}>{children}</TabPane>;
};

export const CompanyContent = ({ i18n, title, text, col, print = false }) => {
  const { t: translate } = useTranslation();

  return (
    <Col span={col}>
      <CompanySubtitle2 level={5}>{translate(`${i18n}.${title}`)}</CompanySubtitle2>
      <CompanyBody1>{text}</CompanyBody1>
    </Col>
  );
};

export const CompanyComment = ({ text, col, type }) => {
  const icon = {
    Neutral: <Less color="#323232" fontSize={22} />,
    Positive: <ThumpsUp color="#106B31" fontSize={22} />,
    Negative: <ThumpsUp1 color="#E21B45" fontSize={22} />,
  };
  return (
    <Col
      span={24}
      className="mrg-btm-10 pdd-btm-10"
      style={{ borderBottom: '1px solid #D1D1D1' }}
    >
      <Row className="flex center">
        <Col span={col[0]} className="flex center">
          {icon[type || 'Neutral']}
        </Col>
        <Col span={col[1]}>
          <CompanyBody1>{text}</CompanyBody1>
        </Col>
      </Row>
    </Col>
  );
};

export const CompanyArrayContent = ({
  i18n,
  title,
  fields,
  col,
  count,
  value = null,
  fieldsToShow = 2,
  extraInfo = null,
}) => {
  const { t: translate } = useTranslation();
  return (
    <Col span={col}>
      <CompanySubtitle2 level={5}>
        {translate(`${i18n}.${title}`)} ({count || '0'})
      </CompanySubtitle2>
      {!count && <NotAvailable />}
      {fields?.slice(0, fieldsToShow).map((field) => (
        <p>
          <CompanyBody1>{value ? field[value] : field}</CompanyBody1>
          {extraInfo ? <CompanyBody1> - {field[extraInfo]}%</CompanyBody1> : null}
        </p>
      ))}
      {count > fieldsToShow ? <p>+{count - fieldsToShow}</p> : <></>}
    </Col>
  );
};

export const CompanyTagging = ({ className, children, value, level, ...rest }) => {
  const text = (value?.toString() || '').toLowerCase();
  const forceOtherLettersAsSuccessEvenWithoutKnowing = ['b', 'c', 'd', 'e', 'f'];
  let newClass = ['low', 'a', ...forceOtherLettersAsSuccessEvenWithoutKnowing].includes(
    text
  )
    ? 'ant-tag-success'
    : 'ant-tag-warning';

  if (text.search(/active/gi) > -1) newClass = 'ant-tag-success';
  if (text.search(/inactive/gi) > -1) newClass = 'ant-tag-error';
  if (text.search(/medium/gi) > -1) newClass = 'ant-tag-warning';
  if (text.search(/low/gi) > -1) newClass = 'ant-tag-success';
  if (text.search(/critical/gi) > -1 || text.search(/high/gi) > -1) {
    newClass = 'ant-tag-error';
  }

  if (value < 40) newClass = 'ant-tag-success';
  if (value >= 40) newClass = 'ant-tag-warning';
  if (value >= 60) newClass = 'ant-tag-error';

  const levels = ['critical', 'high', 'elevated', 'relevant'];
  if (levels.includes(level) || levels.includes(children)) {
    newClass = `ant-tag-${level || children}`;
  }

  return (
    <CompanyTag className={classnames(className, newClass)} {...rest}>
      {children}
    </CompanyTag>
  );
};

export const CompanyFinancialRiskTagging = ({ children, i18n, value, ...rest }) => {
  if (!value || value === '-') {
    return <CompanyTag empty />;
  }
  return (
    <CompanyTagging i18n={i18n} value={value} {...rest}>
      {children}
    </CompanyTagging>
  );
};

export const CompanyRiskFactorTagging = ({ i18n, value }) => {
  const { t } = useTranslation();
  let value0Until100 = 100;
  let title = value?.toString();
  if (typeof value === 'boolean') {
    value0Until100 = value ? 100 : 0;
  } else if (typeof value === 'number') {
    value0Until100 = value * 10 > 100 ? value : value * 10;
    title = value0Until100;
  } else {
    return <NotAvailable />;
  }
  let text = 'low';
  if (value0Until100 >= 25) text = 'moderate';
  if (value0Until100 >= 40) text = 'elevated';
  if (value0Until100 >= 60) text = 'high';
  if (value0Until100 >= 85) text = 'critical';
  return (
    <CompanyTagging value={value0Until100} title={title}>
      {t(`${i18n}.${text}`, '') || <NotAvailable />}
    </CompanyTagging>
  );
};

export const CompanyStatus = ({ children }) => {
  const { t: translate } = useTranslation();
  const companyStatus = () => {
    const status = children?.toLowerCase();

    switch (status) {
      case 'active':
        return {
          description: translate(`${STATUS_PATH}.status.active`, 'Active'),
          value: 'active',
        };
      case 'nonactive':
      case 'closed':
      case 'expired':
      case 'inactive':
      case 'terminated':
        return {
          description: translate(`${STATUS_PATH}.status.inactive`, 'Inactive'),
          value: 'inactive',
        };
      case 'pending':
      case 'other':
      case 'closing':
      case 'expanded':
      case 'incorporated':
      case 'registered':
      case 'under_external_control':
        return {
          description: translate(`${STATUS_PATH}.status.other`, 'Other'),
          value: 'other',
        };
      default:
        return {
          description: '-',
          color: '',
        };
    }
  };

  return companyStatus()?.value ? (
    <CompanyTagging value={companyStatus()?.value}>
      {companyStatus()?.description}
    </CompanyTagging>
  ) : (
    <NotAvailable />
  );
};

export const CompanyAddress = ({ i18n, title, text, col, print = false }) => {
  const { t: translate } = useTranslation();

  return (
    <Col span={col}>
      <CompanySubtitle2>{translate(`${i18n}.${title}`)}</CompanySubtitle2>
      <CompanyBody1>{text}</CompanyBody1>
    </Col>
  );
};

export const CompanySupplierGroupButtons = ({ value, onChange, render = true }) => {
  if (!render) return null;

  return (
    <Radio.Group
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="kyb-company-info-supplier-group-buttons"
    >
      <Radio.Button value="credit-report">Credit Report</Radio.Button>
      <Radio.Button value="business-identity">Business Identity</Radio.Button>
    </Radio.Group>
  );
};

export const CompanyPopover = ({ children, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      overlayClassName="kyb-company-popover-overlay"
      placement="topLeft"
      content={children}
      trigger="click"
      open={open}
      onOpenChange={setOpen}
    >
      <Button size="small" className="kyb-company-popover-button">
        {text}
      </Button>
    </Popover>
  );
};

export const CompanyTextToPopover = ({ payload }) => {
  if (Array.isArray(payload) && !payload.length) {
    return <NotAvailable />;
  }

  return (
    <CompanyBody1>
      {payload[0]}
      {payload.length < 2 ? null : (
        <CompanyPopover text={`+ ${payload.length - 1}`}>
          {payload.slice(1, payload.length).map((item) => (
            <div key={item} className="kyb-company-popover-content">
              {item}
            </div>
          ))}
        </CompanyPopover>
      )}
    </CompanyBody1>
  );
};

export const CompanyCollapseItem = ({ payload }) => {
  const keys = Object.keys(payload);

  if (!keys?.length) {
    return null;
  }

  return keys.map((item) => (
    <div key={item}>
      <CompanySubtitle2>{item?.replace(/_/g, ' ')}</CompanySubtitle2>
      <CompanyBody1>{payload[item]}</CompanyBody1>
    </div>
  ));
};

export const CompanyCollapse = ({ i18n, payload, ...rest }) => {
  const { t } = useTranslation();

  const name = t(`${i18n}.api.${payload?.name}`, '');
  const header = !name ? (
    <NotAvailable title={payload?.name} />
  ) : (
    <b>{`${name} (${payload.total})`}</b>
  );
  let hasData = false;

  if (Array.isArray(payload?.data)) {
    hasData = Boolean(Object.keys(payload.data[0]?.properties).length);
  }

  if (!hasData) {
    return null;
  }

  return (
    <Collapse
      defaultActiveKey={rest.open ? [rest.name] : []}
      className="kyb-company-collapse"
      expandIconPosition="end"
      {...rest}
    >
      <Collapse.Panel header={header} key={rest.name}>
        {payload?.data?.map((data) => (
          <div className="kyb-company-collapse-item kyb-company-print-break-avoid">
            <section className="kyb-company-info-section">
              <article className="kyb-company-info-article">
                <CompanyCollapseItem payload={data?.properties} />
              </article>
            </section>
          </div>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

const INLINE_STATUS =
  'pages.private.KYBCompany.pages.companyDetails.components.executionCardStatus.buttons';

export const CompanyExecutionStatus = ({ status }) => {
  const { t } = useTranslation();
  const data = status?.toLowerCase();
  const label = t(`${INLINE_STATUS}.${data}`, '');
  if (!label) return <NotAvailable />;
  let antTag = 'ant-tag-warning';
  if (['reject', 'reproved'].includes(data)) {
    antTag = 'ant-tag-error';
  }
  if (data === 'approved') {
    antTag = 'ant-tag-success';
  }
  return (
    <CompanyTagging
      value={label}
      className={classnames('kyb-company-execution-status', antTag, data)}
    >
      {label}
    </CompanyTagging>
  );
};

export const CompanySummaryCounterCard = ({ payload, ...rest }) => {
  return (
    <div
      className={classnames(
        'kyb-company-counter-card',
        'kyb-company-print-break-avoid',
        rest.className
      )}
    >
      {payload.map((item) => (
        <div>
          <CompanyHeadlineSmall className="kyb-company-counter-card-number">
            {item.count}
          </CompanyHeadlineSmall>
          <CompanyCaption>{item.text}</CompanyCaption>
        </div>
      ))}
    </div>
  );
};

export const CompanyGroupSummary = ({ payload, ...rest }) => {
  const hasInfo = payload.some((item) => Boolean(item.info));

  if (!hasInfo) return null;

  return (
    <div
      className={classnames(
        'kyb-company-counter-card',
        'kyb-company-print-break-avoid',
        rest.className
      )}
    >
      {payload.map((item) =>
        !item.info ? null : (
          <div>
            <CompanySubtitle2>{item.text}</CompanySubtitle2>
            <CompanyBody1>{item.info}</CompanyBody1>
          </div>
        )
      )}
    </div>
  );
};

const propTypes = {
  i18n: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  col: PropTypes.string.isRequired,
};
const propTypesTagging = {
  value: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
const propTypesRiskTagging = {
  i18n: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

CompanyExecutionStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
CompanySummaryCounterCard.propTypes = {
  payload: PropTypes.array.isRequired,
};
CompanyGroupSummary.propTypes = CompanySummaryCounterCard.propTypes;
CompanyTextToPopover.propTypes = {
  payload: PropTypes.array.isRequired,
};
CompanyCollapse.propTypes = {
  i18n: PropTypes.string.isRequired,
  payload: PropTypes.object.isRequired,
};
CompanyPopover.propTypes = {
  text: PropTypes.string.isRequired,
};
CompanySupplierGroupButtons.propTypes = {
  render: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
CompanyArrayContent.propTypes = {
  i18n: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  col: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  value: PropTypes.string,
  fieldsToShow: PropTypes.number,
  extraInfo: PropTypes.string,
};
CompanyArrayContent.defaultProps = {
  value: null,
  fieldsToShow: 2,
  extraInfo: null,
};
CompanyTagging.propTypes = propTypesTagging;
CompanyRiskFactorTagging.propTypes = propTypesRiskTagging;
CompanyFinancialRiskTagging.propTypes = propTypesRiskTagging;
CompanyAddress.propTypes = {
  ...propTypes,
  print: PropTypes.bool.isRequired,
};
CompanyContent.propTypes = {
  ...propTypes,
  print: PropTypes.bool.isRequired,
};
CompanyComment.propTypes = {
  text: PropTypes.string.isRequired,
  col: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};
CompanyTabs.propTypes = {
  print: PropTypes.bool.isRequired,
};
CompanyTabPane.propTypes = {
  tab: PropTypes.string.isRequired,
  print: PropTypes.bool.isRequired,
  render: PropTypes.bool.isRequired,
};
