import React from 'react';
import classnames from 'classnames';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import {
  CompanyRow,
  CompanyCard,
  CompanyLink,
  CompanySubtitle1,
  CompanyContent,
  CompanyAddress,
  CompanyStatus,
  CompanySubtitle2,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import PropTypes from 'prop-types';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages';
const SUMMARY = `${I18N_BASE_PATH}.companyDetails.subPages.summary`;
const INFO = `${SUMMARY}.cards.companyIdentificationInformation`;
const DETAILS = `${SUMMARY}.cards.companyDetails`;

const CompanyInformation = ({ summary, disabled, print = false, ...rest }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  if (disabled) {
    return null;
  }

  return (
    <CompanyCard className={classnames('kyb-company-print-break-avoid', rest.className)}>
      <Row>
        <Col span={24}>
          <CompanySubtitle1>
            {translate(`${SUMMARY}.cards.companyIdentificationInformation.title`)}
          </CompanySubtitle1>
        </Col>
      </Row>
      <CompanyRow>
        <CompanyContent
          i18n={INFO}
          title="identifier"
          text={summary.identifier}
          col={5}
          print={print}
        />
        <CompanyContent
          i18n={INFO}
          title="taxId"
          text={summary.taxId}
          col={4}
          print={print}
        />
        <Col span={4}>
          <CompanySubtitle2>{translate(`${INFO}.status`)}</CompanySubtitle2>
          <CompanyStatus>{summary.statusCode}</CompanyStatus>
        </Col>
        <CompanyContent
          i18n={INFO}
          title="reg"
          text={summary.reg}
          col={5}
          print={print}
        />
        <CompanyContent
          i18n={INFO}
          title="registrationDate"
          text={
            summary.registrationDate
              ? i18nFormatDateOnly(summary.registrationDate, 1)
              : null
          }
          col={6}
          print={print}
        />
      </CompanyRow>
      <Row gutter={16}>
        <CompanyAddress
          i18n={DETAILS}
          title="address"
          text={summary.address}
          col={9}
          print={print}
        />
        <CompanyContent
          i18n={DETAILS}
          title="telephone"
          text={summary.telephone}
          col={4}
          print={print}
        />
        <CompanyContent
          i18n={DETAILS}
          title="email"
          text={summary.email}
          col={5}
          print={print}
        />
        <CompanyContent
          i18n={DETAILS}
          title="url"
          text={summary.url}
          col={6}
          print={print}
        />
      </Row>
      <Row justify="end">
        <Col className="kyb-company-summary-footer">
          <CompanyLink to="?tab=companyInfo">
            {translate(`${I18N_BASE_PATH}.companyDetails.mainCardLink`)}
          </CompanyLink>
        </Col>
      </Row>
    </CompanyCard>
  );
};

CompanyInformation.propTypes = {
  summary: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CompanyInformation;
