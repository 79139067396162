import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CompanyRow,
  CompanyCard,
  CompanyLink,
  CompanySubtitle1,
  CompanyArrayContent,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import PropTypes from 'prop-types';
import NotAvailable from 'components/NotAvailable';

const I18N_BASE_PATH = 'pages.private.KYBCompany.pages';
const SUMMARY = `${I18N_BASE_PATH}.companyDetails.subPages.summary`;
const PEOPLE = `${SUMMARY}.cards.people`;

const People = ({ summary, disabled, print = false }) => {
  const { t: translate } = useTranslation();

  return (
    <Col span={12}>
      <CompanyCard className="kyb-company-summary-people-card kyb-company-print-break-avoid">
        <Row>
          <Col span={24}>
            <CompanySubtitle1>{translate(`${PEOPLE}.title`)}</CompanySubtitle1>
          </Col>
        </Row>
        {summary?.directorsLength ||
        summary?.UBOandPSCLength ||
        summary?.shareHoldersLength ? (
          <>
            <CompanyRow className="kyb-company-summary-people-content">
              <CompanyArrayContent
                i18n={PEOPLE}
                title="directors"
                count={summary?.directorsLength}
                fields={summary?.directors}
                fieldsToShow={2}
                value="name"
                col={12}
              />
              <CompanyArrayContent
                i18n={PEOPLE}
                title="UBOAndPSC"
                count={summary?.UBOandPSCLength}
                fields={summary?.UBOandPSC}
                fieldsToShow={2}
                value="name"
                col={12}
              />
              <CompanyArrayContent
                i18n={PEOPLE}
                title="shareholders"
                count={summary?.shareHoldersLength}
                fields={summary?.shareHolders}
                fieldsToShow={2}
                value="name"
                extraInfo="percentSharesHeld"
                col={12}
              />
            </CompanyRow>
            <Row justify="end">
              <Col className="kyb-company-summary-footer">
                <CompanyLink to="?tab=people">
                  {translate(`${I18N_BASE_PATH}.companyDetails.mainCardLink`)}
                </CompanyLink>
              </Col>
            </Row>
          </>
        ) : (
          <NotAvailable card />
        )}
      </CompanyCard>
    </Col>
  );
};

People.propTypes = {
  summary: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  print: PropTypes.bool.isRequired,
};

export default People;
