/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { CompanySubtitle1, CompanySubtitle2, CompanyBody1 } from '../atoms';
import { CompanyCollapse, CompanyStatus, CompanyTextToPopover } from '../molecules';

const PAGE = 'pages.private.KYBCompany.pages';
const BASE = `${PAGE}.companyDetails`;
const I18N = `${BASE}.subPages.companyInfo`;
const SUMMARY = `${BASE}.subPages.summary`;
const INFO = `${SUMMARY}.cards.companyIdentificationInformation`;
const REPORT = `${I18N}.businessIdentity.companyIdentificationInformation`;
const ATTR = `${I18N}.businessIdentity.attributes`;
const HOLDING = `${SUMMARY}.cards.ultimateHoldingCompany`;
const PRINT = `${BASE}.printModal.companyInfo`;

export const CompanyAttributes = ({ payload, print, itemsToExport }) => {
  const { t } = useTranslation();

  if (itemsToExport?.value === false) return null;

  if (!payload?.businessIdentity?.attributes?.length) {
    return null;
  }

  payload.businessIdentity.attributes = payload?.businessIdentity?.attributes.map(
    (item) => ({ ...item, total: Object.keys(item.data)?.length })
  );

  return (
    <section className="kyb-company-info-section">
      <CompanySubtitle1>{t(`${ATTR}.title`)}</CompanySubtitle1>
      {payload?.businessIdentity?.attributes.map((item) => (
        <CompanyCollapse
          i18n={ATTR}
          payload={item}
          key={item.name}
          name={item.name}
          open={print}
        />
      ))}
    </section>
  );
};

export const CompanyBusinessIdentity = ({ payload, itemsToExport, print = false }) => {
  const { t } = useTranslation();
  const { i18nFormatDateOnly } = useI18nFormatters();

  const sources = payload?.businessIdentity?.sources.map(
    (item) => `${item.name} (${item.total})`
  );

  if (itemsToExport?.value === false) return null;

  return (
    <div>
      {!print ? null : <br />}
      <CompanySubtitle1 render={print}>
        {t(`${PRINT}.businessIdentity`, 'Business identity')}
      </CompanySubtitle1>
      <section className="kyb-company-info-section">
        <CompanySubtitle1>
          {t(`${SUMMARY}.cards.companyIdentificationInformation.title`)}
        </CompanySubtitle1>
        <article className="kyb-company-info-article">
          <div>
            <CompanySubtitle2>{t(`${INFO}.identifier`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.businessIdentity?.identifier}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${INFO}.status`)}</CompanySubtitle2>
            <CompanyStatus>{payload?.businessIdentity?.status}</CompanyStatus>
          </div>
          <div>
            <CompanySubtitle2>
              {t(`${REPORT}.columns.registrationIncorporationDate`)}
            </CompanySubtitle2>
            <CompanyBody1>
              {i18nFormatDateOnly(payload?.businessIdentity?.registrationDate || '', 1)}
            </CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.companyType`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.businessIdentity?.companyType}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${HOLDING}.columns.companyName`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.businessIdentity?.name}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.bussinessPurpose`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.businessIdentity?.bussinessPurpose}</CompanyBody1>
          </div>
          <div>
            <CompanySubtitle2>
              {t(`${REPORT}.columns.ownersShareholders`)}
            </CompanySubtitle2>
            <CompanyTextToPopover
              payload={payload?.businessIdentity?.ownersShareholders}
            />
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.names`)}</CompanySubtitle2>
            <CompanyTextToPopover payload={payload?.businessIdentity?.names} />
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.sources`)}</CompanySubtitle2>
            <CompanyTextToPopover payload={sources} />
          </div>
          <div>
            <CompanySubtitle2>{t(`${REPORT}.columns.address`)}</CompanySubtitle2>
            <CompanyBody1>{payload?.businessIdentity?.address}</CompanyBody1>
          </div>
        </article>
      </section>
      <CompanyAttributes
        payload={payload}
        print={print}
        itemsToExport={itemsToExport?.items.attributes}
      />
    </div>
  );
};

CompanyBusinessIdentity.propTypes = {
  payload: PropTypes.object.isRequired,
  itemsToExport: PropTypes.object.isRequired,
};
CompanyAttributes.propTypes = CompanyBusinessIdentity.propTypes;
