import React, { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, message, Button, Avatar, Tooltip, Col, Row } from 'antd';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import SearchInput from 'components/Form/SearchInput';
import _ from 'lodash';
import { useAuth } from 'hooks/auth';
import { useGroup } from 'hooks/group';
import { useFetch } from 'services/hooks';
import useDynamoTable from 'components/List/hooks/useDynamoTable';
import PermissionWrapper from 'routes/PermissionWrapper';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { Info } from '@combateafraude/icons/general';
import { isGroupAdmin } from 'utils/translations';

import ActionsList from './components/ActionsList';

import useChangeUserStatus from './components/ChangeUserStatus';
import SwitchRender from './components/SwitchRenderer';
import './styles.less';

const { Option } = Select;

const I18N = `pages.private.users.components.usersList.index`;

const UsersList = () => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();

  const history = useHistory();
  const { user } = useAuth();
  const refreshListRef = useRef(() => {});
  const { post } = useFetch();
  const { listGroups, loading, getListGroups } = useGroup();
  const [query] = useQueryParams({
    search: StringParam,
    active: withDefault(StringParam, `true`),
  });

  const { openModal: openChangeStatusModal, ChangeUserStatusModal } = useChangeUserStatus(
    {
      refreshListRef,
    }
  );

  const changeUserGroup = useCallback(
    async (userId, groupId) => {
      try {
        await post({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users-groups/${
            groupId || 'no_access'
          }/users`,
          payload: { usersIds: [userId] },
        });
        message.success(translate(`${I18N}.changeUserGroup.messageSuccess`));
      } catch {
        message.error(translate(`${I18N}.changeUserGroup.messageError`));
      }
    },
    [post, translate]
  );

  const findGroupById = useCallback(
    (id) => {
      const group = listGroups?.find((g) => g.id === id);
      return group?.name || translate('global.hooks.group.getListGroups.noAccess');
    },
    [listGroups, translate]
  );

  const columns = [
    {
      key: 'name',
      title: <span className="column-title">{translate(`${I18N}.columns.name`)}</span>,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (__, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record?.profileImageUrl ? (
            <Avatar src={record?.profileImageUrl}>
              {record?.name?.charAt(0)?.toUpperCase()}
            </Avatar>
          ) : (
            <Avatar style={{ backgroundColor: '#004af7' }}>
              {record?.name?.charAt(0)?.toUpperCase()}
            </Avatar>
          )}

          <b className="mrg-left-10">{record?.name}</b>
          {record?.id === user?.id && (
            <span className="ant-tag mrg-left-10 tag-you">
              {translate(`${I18N}.you`)}
            </span>
          )}
          {(record?.blockedByMfaAuthentication || record?.blockedByAccountSuspended) && (
            <span className="mrg-left-5">
              <Tooltip
                title={translate(
                  record?.blockedByMfaAuthentication
                    ? `${I18N}.mfaBlockedUser`
                    : `${I18N}.accountSuspended`
                )}
                autoAdjustOverflow
                arrowPointAtCenter
                color="#323232"
                trigger="hover"
                overlayInnerStyle={{
                  padding: '0.5rem',
                }}
              >
                <Info
                  style={{
                    color: '#FF8A00',
                    height: '20px',
                    width: '20px',
                  }}
                />
              </Tooltip>
            </span>
          )}
        </div>
      ),
    },
    {
      key: 'email',
      title: <span className="column-title">{translate(`${I18N}.columns.email`)}</span>,
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      key: 'updatedAt',
      title: (
        <span className="column-title">{translate(`${I18N}.columns.updatedAt`)}</span>
      ),
      dataIndex: 'updatedAt',
      sorter: (a, b) => a?.updatedAt?.localeCompare(b?.updatedAt),
      render: (__, record) => i18nFormatDate(record?.updatedAt || record?.createdAt, 1),
    },
    {
      key: 'group',
      title: <span className="column-title">{translate(`${I18N}.columns.group`)}</span>,
      dataIndex: 'group',
      render: (__, record) =>
        !loading && (
          <PermissionWrapper
            product="management"
            requiredPermissions={['users:update']}
            forbiddenContent={
              <span className="text-dark text-bold no-pdd">
                {findGroupById(record?.usersGroupId)}
              </span>
            }
          >
            <Select
              showSearch
              optionFilterProp="children"
              bordered={false}
              style={{ width: '200px' }}
              className="text-dark text-bold no-pdd"
              defaultValue={record?.usersGroupId || ''}
              onSelect={(e) => changeUserGroup(record?.id, e)}
            >
              {isGroupAdmin(user?.usersGroupId)
                ? listGroups?.map((group) => (
                    <Option value={group?.id}>
                      {isGroupAdmin(group?.id)
                        ? translate('global.hooks.group.getListGroups.admin')
                        : group?.name}
                    </Option>
                  ))
                : listGroups?.map(
                    (group) =>
                      !isGroupAdmin(group?.id) && (
                        <Option value={group?.id}>{group?.name}</Option>
                      )
                  )}
            </Select>
          </PermissionWrapper>
        ),
    },
    {
      key: 'deletedAt',
      title: <span className="column-title">{translate(`${I18N}.columns.status`)}</span>,
      render: (__, record) => (
        <SwitchRender data={record} openModal={openChangeStatusModal} />
      ),
    },
    {
      key: 'actions',
      render: (__, record) => (
        <ActionsList user={record} refreshListRef={refreshListRef} />
      ),
      width: 60,
    },
  ];

  const { tableContent, refreshList, setFilterData } = useDynamoTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/all`,
      config: {
        params: {},
      },
    },
    queryParams: {
      search: StringParam,
      active: withDefault(StringParam, 'true'),
    },
    columns,
    rowKey: 'id',
    customLoading: loading,
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        active: values.active && values.active !== 'all' ? values.active : undefined,
        search: values._search ? values._search.trim() : undefined,
        usersGroupId: values?.group !== 'all' ? values?.group : undefined,
      });
    }, 500),
    []
  );

  useEffect(() => {
    getListGroups();
  }, [getListGroups]);

  return (
    <>
      {ChangeUserStatusModal}
      <Col className="mrg-left-20 mrg-right-50" justify="space-between">
        <Row justify="space-between">
          <PageTitle
            title={translate(`${I18N}.pageTitle.title`)}
            subtitle={translate(`${I18N}.pageTitle.subtitle`)}
          />
          <Row>
            <Form
              layout="horizontal"
              className="flex align-center"
              onValuesChange={handleFormChange}
              initialValues={{ _search: query?.search, active: query?.active }}
            >
              <Form.Item name="group" className="no-mrg-btm">
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{
                    width: 200,
                    fontWeight: 500,
                    marginRight: 30,
                    marginLeft: 30,
                  }}
                  className="text-dark text-bold no-pdd"
                  defaultValue="all"
                >
                  <Option value="all">{translate(`${I18N}.group.all`)}</Option>
                  {listGroups?.map(
                    (group) =>
                      group?.id && (
                        <Option value={group?.id}>
                          {isGroupAdmin(group?.id)
                            ? translate('global.hooks.group.getListGroups.admin')
                            : group?.name}
                        </Option>
                      )
                  )}
                </Select>
              </Form.Item>
              <Form.Item name="active" className="no-mrg-btm">
                <Select
                  bordered={false}
                  style={{ width: 100, fontWeight: 500, marginRight: 20 }}
                  className="text-dark"
                >
                  <Option value="all">{translate(`${I18N}.activeInput.all`)}</Option>
                  <Option value="true">{translate(`${I18N}.activeInput.active`)}</Option>
                  <Option value="false">
                    {translate(`${I18N}.activeInput.inactive`)}
                  </Option>
                </Select>
              </Form.Item>
              <SearchInput placeholder={translate(`${I18N}.searchInput`)} />
            </Form>
            <PermissionWrapper
              requiredPermissions={[`users:create`]}
              product="management"
            >
              <div className="mrg-left-15 mrg-auto">
                <Button
                  type="primary"
                  ghost
                  className="flex center"
                  onClick={() => history.push('/users/create')}
                >
                  <i className="caf-ic_plus font-size-15 mrg-right-5" />
                  <span>{translate(`${I18N}.newUser`)}</span>
                </Button>
              </div>
            </PermissionWrapper>
          </Row>
        </Row>
      </Col>
      <div className="gx-w-90 mrg-top-10 users-table">{tableContent}</div>
    </>
  );
};

export default UsersList;
