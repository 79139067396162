import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { Form, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';
import { useProfileTemplate } from 'hooks/profileTemplate';

import Button from 'components/Button';
import EmptyMessage from 'components/EmptyMessage';
import ComplianceRule from './ComplianceRule';

const NON_COEXISTING_RULES = [];

const createRule = (validation, translate, showSubjectTitle) => ({
  key: validation.rule,
  label: (
    <Tooltip
      placement="left"
      title={
        translate(`validations.${validation.rule}.details`, validation.details) ||
        translate(`validations.${validation.rule}.description`, validation.description)
      }
    >
      <span className="width-100">{`${
        showSubjectTitle && validation.subjectTitle
          ? translate(
              `validations.${validation.rule}.subjectTitle`,
              validation.subjectTitle
            )
          : translate(`validations.${validation.rule}.title`, validation.title)
      }`}</span>
    </Tooltip>
  ),
  value: validation.rule,
});

const giveNameAndATooltipToInput = (
  validations,
  formRef,
  formFieldName,
  profileTemplateId,
  translate,
  showSubjectTitle,
  selectedValidationsCount,
  setSelectedValidationsCount,
  onValuesChange,
  filterNonCoexistingValidations,
  setOtherValidations,
  formValues,
  isStartingToCreateNewProfileModel,
  setIsStartToCreateNewProfileModel,
  clearProducRule
) => {
  const selectedValidations = [];
  const unselectedValidations = [];
  const getFieldValuesForEdit = validations?.filter(
    (x) => clearProducRule?.filter((y) => y?.rule === x?.rule).length
  );
  const arrayUsedToRender = profileTemplateId ? getFieldValuesForEdit : validations;

  arrayUsedToRender.forEach((validation) => {
    const formValue = formValues.find(({ rule }) =>
      [rule?.value, rule].includes(validation.rule)
    );

    if (formValue || (!profileTemplateId && validation.category !== 'onboarding')) {
      selectedValidations.push({
        ...validation,
        ...formValue,
        rule: createRule(validation, translate, showSubjectTitle),
      });
    } else {
      unselectedValidations.push(validation);
    }
  });

  formValues.forEach((formValue) => {
    const { rule } = formValue;
    const ruleName = rule?.value || rule;

    const isNotSelectedValidations = selectedValidations.find(
      ({ rule }) => rule.value === ruleName
    );

    if (!isNotSelectedValidations) {
      const validation = validations.find((validation) => validation.rule === ruleName);

      if (validation) {
        selectedValidations.push({
          ...validation,
          ...formValue,
          rule: createRule(
            {
              rule: validation?.rule,
              details: validation?.details,
              description: validation?.details,
            },
            translate,
            showSubjectTitle
          ),
        });
      }
    }
  });

  const filteredValidations = filterNonCoexistingValidations(unselectedValidations);

  if (selectedValidations.length !== selectedValidationsCount) {
    const data = { [formFieldName]: selectedValidations };

    if (formValues.rule || profileTemplateId || isStartingToCreateNewProfileModel) {
      formRef.setFieldsValue(data);
    }

    if (onValuesChange) {
      setIsStartToCreateNewProfileModel(false);
      onValuesChange(data);
    }

    setSelectedValidationsCount(selectedValidations.length);
  }

  setOtherValidations(filteredValidations);
};

const ValidationsFormSelector = ({
  formFieldName,
  validations,
  disabled,
  formRef,
  onValuesChange,
}) => {
  const { t: translate } = useTranslation();

  const { profileTemplateId } = useParams();
  const {
    profileTemplate,
    isStartingToCreateNewProfileModel,
    setIsStartToCreateNewProfileModel,
    cleanProfileTemplate,
  } = useProfileTemplate();

  const [otherValidations, setOtherValidations] = useState([]);
  const [selectedValidationsCount, setSelectedValidationsCount] = useState(0);

  const cleanBackgroundChecking = cleanProfileTemplate?.backgroundChecking?.validations?.filter(
    (v) => v?.rule
  );
  const cleanOnboarding = cleanProfileTemplate?.onboarding?.validations?.filter(
    (v) => v?.rule
  );

  const clearProducRule = useMemo(() => {
    return cleanBackgroundChecking?.concat(cleanOnboarding);
  }, [cleanBackgroundChecking, cleanOnboarding]);

  const { user } = useAuth();
  const showSubjectTitle = useMemo(
    () => !!user?.accountData?.featureFlags?.useRulesSubjectTitle,
    [user]
  );

  const [query] = useQueryParams({
    profileType: StringParam,
    type: StringParam,
  });

  const I18N_BASE_PATH =
    'pages.private.profileModels.components.complianceRules.validationsFormSelector';

  const formValues = formRef?.getFieldValue(formFieldName) || [];

  const emptyMessage = useMemo(() => {
    if (formFieldName === 'backgroundCheckingValidations') {
      return translate(`${I18N_BASE_PATH}.emptyMessage.backgroundCheckingValidations`);
    }
    if (formFieldName === 'onboardingValidations') {
      return translate(`${I18N_BASE_PATH}.emptyMessage.onboardingValidations`);
    }
    return translate(`${I18N_BASE_PATH}.emptyMessage.default`);
  }, [formFieldName, translate]);

  const emptyFormListMessage = useMemo(() => {
    if (formFieldName === 'profileRules') {
      return translate(`${I18N_BASE_PATH}.emptyFormListMessage.profileRules`);
    }
    return translate(`${I18N_BASE_PATH}.emptyFormListMessage.default`);
  }, [formFieldName, translate]);

  const actionWhenInvalidOptions = useCallback(
    (rule) => {
      if (formFieldName === 'profileRules') {
        if (
          query?.type === 'PF' ||
          query?.profileType === 'PF' ||
          rule === 'completed_qsa_onboarding'
        ) {
          return [
            {
              title: translate(
                `${I18N_BASE_PATH}.actionWhenInvalidOptions.profileRules.completedQsaOnboarding.title`
              ),
              value: 'PENDING_SUBMISSION',
            },
          ];
        }
        return [
          {
            title: translate(
              `${I18N_BASE_PATH}.actionWhenInvalidOptions.profileRules.default.title.reprove`
            ),
            value: 'REPROVE',
          },
          {
            title: translate(
              `${I18N_BASE_PATH}.actionWhenInvalidOptions.profileRules.default.title.pending`
            ),
            value: 'PENDING',
          },
          {
            title: translate(
              `${I18N_BASE_PATH}.actionWhenInvalidOptions.profileRules.default.title.pendingOCR`
            ),
            value: 'PENDING_OCR',
          },
        ];
      }

      return [
        {
          title: translate(
            `${I18N_BASE_PATH}.actionWhenInvalidOptions.default.title.reprove`
          ),
          value: 'REPROVE',
        },
        {
          title: translate(
            `${I18N_BASE_PATH}.actionWhenInvalidOptions.default.title.pending`
          ),
          value: 'PENDING',
        },
        {
          title: translate(
            `${I18N_BASE_PATH}.actionWhenInvalidOptions.default.title.pendingOCR`
          ),
          value: 'PENDING_OCR',
        },
      ];
    },
    [formFieldName, query, translate]
  );

  const hasCustomStatus = useMemo(
    () => user?.accountData?.featureFlags?.useCustomStatus,
    [user]
  );

  const profileTemplateValidations = useMemo(() => {
    return profileTemplate?.[formFieldName] || [];
  }, [formFieldName, profileTemplate]);

  const filterNonCoexistingValidations = useCallback(
    (unselectedValidations) => {
      const hasNoCoexistingRules = formValues.some(({ rule }) =>
        NON_COEXISTING_RULES.includes(rule?.key)
      );

      if (!hasNoCoexistingRules) return unselectedValidations;

      return unselectedValidations?.filter(
        (r) => !NON_COEXISTING_RULES.includes(r?.rule?.key || r.rule)
      );
    },
    [formFieldName, formRef]
  );

  const unselectedValues = profileTemplateId ? formValues : profileTemplateValidations;

  useEffect(() => {
    giveNameAndATooltipToInput(
      validations,
      formRef,
      formFieldName,
      profileTemplateId,
      translate,
      showSubjectTitle,
      selectedValidationsCount,
      setSelectedValidationsCount,
      onValuesChange,
      filterNonCoexistingValidations,
      setOtherValidations,
      formValues,
      isStartingToCreateNewProfileModel,
      setIsStartToCreateNewProfileModel,
      clearProducRule
    );
    const unselectedValidations = validations?.filter(
      (validation) =>
        !unselectedValues?.some(({ rule }) =>
          [rule?.value, rule].includes(validation.rule)
        )
    );

    const filteredValidations = filterNonCoexistingValidations(unselectedValidations);

    setOtherValidations(filteredValidations);
  }, [
    filterNonCoexistingValidations,
    formFieldName,
    formRef,
    profileTemplateValidations,
    validations,
  ]);

  return (
    <>
      {!disabled && validations?.length <= 0 ? (
        <>
          {query?.profileType !== translate(`${I18N_BASE_PATH}.type.company`) &&
            query?.type !== translate(`${I18N_BASE_PATH}.type.company`) && (
              <EmptyMessage show description={emptyMessage} withCard={false} />
            )}
        </>
      ) : (
        <Form.List name={formFieldName}>
          {(fields, { add, remove }) => (
            <>
              {fields.length < 1 && disabled && (
                <EmptyMessage
                  show
                  description={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.emptyMessage.description`
                  )}
                  withCard={false}
                />
              )}

              {fields.map((field, index) => (
                <ComplianceRule
                  field={field}
                  index={index}
                  hasCustomStatus={hasCustomStatus}
                  actionWhenInvalidOptions={actionWhenInvalidOptions}
                  remove={remove}
                  otherValidations={otherValidations}
                  formFieldName={formFieldName}
                  disabled={disabled}
                  showSubjectTitle={showSubjectTitle}
                  profileTemplate={profileTemplate}
                  formValues={formValues}
                  clearProducRule={clearProducRule}
                />
              ))}
              {!disabled && otherValidations?.length > 0 && fields.length === 0 && (
                <EmptyMessage
                  show
                  description={emptyFormListMessage}
                  withCard={false}
                  type="compliance"
                  className="empty-custom"
                />
              )}
              {!disabled && otherValidations?.length > 0 && (
                <Button
                  type="dashed"
                  onClick={add}
                  className={`${
                    fields.length === 0
                      ? 'display-block mrg-horizon-auto no-mrg-top mrg-btm-30'
                      : 'mrg-top-15 mrg-btm-15'
                  }`}
                  disabled={disabled}
                >
                  <i className="caf-ic_plus font-size-14 pdd-right-5" />
                  {translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.button`
                  )}
                </Button>
              )}
            </>
          )}
        </Form.List>
      )}
    </>
  );
};

ValidationsFormSelector.propTypes = {
  formFieldName: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  onValuesChange: PropTypes.func,
};

ValidationsFormSelector.defaultProps = {
  disabled: false,
  onValuesChange: null,
};

export default ValidationsFormSelector;
