import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'antd';

import { useProfileTemplate } from 'hooks/profileTemplate';

import ServiceButton from './ServiceButton';
import { PopoverRemoveValidationsProvider } from '../PopoverRemoveValidations';

import './styles.less';

const OnboardingServices = ({ services, disabled, formRef, onValuesChange, hidden }) => {
  const { profileTemplate, serviceIsOnProduct } = useProfileTemplate();

  const hiddenServices = ['official_data_latam', 'authentic_document', 'face_details'];

  const _onValuesChange = useCallback(
    (value) => {
      const data = {
        onboardingServices: value,
      };
      formRef.setFieldsValue(data);
      if (onValuesChange) onValuesChange(data);
    },
    [formRef, onValuesChange]
  );

  return (
    <section
      hidden={hidden}
      id="profile-model-onboarding-services-component"
      className="gx-w-100"
    >
      {/* <PageTitle
        title="Identidade"
        subtitle="Selecione os serviços que deseja aplicar na validação de documentos e identidades."
      /> */}
      <Form.Item name={['onboardingServices']} className="no-mrg">
        <Row gutter={[16, 14]}>
          {services
            .filter((service) => !hiddenServices.includes(service.source))
            .map((service) => (
              <Col key={service.source} xs={24} sm={12} md={12} lg={8} xl={8}>
                <PopoverRemoveValidationsProvider type="onboarding">
                  <ServiceButton
                    service={service}
                    formRef={formRef}
                    disabled={disabled || serviceIsOnProduct(service.source)}
                    onChange={_onValuesChange}
                    allServices={services}
                    profileTemplate={profileTemplate}
                  />
                </PopoverRemoveValidationsProvider>
              </Col>
            ))}
        </Row>
      </Form.Item>
    </section>
  );
};

OnboardingServices.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  formRef: PropTypes.shape().isRequired,
  onValuesChange: PropTypes.func,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

OnboardingServices.defaultProps = {
  disabled: false,
  hidden: false,
  onValuesChange: null,
};

export default OnboardingServices;
