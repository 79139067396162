import React from 'react';
import PropTypes from 'prop-types';

import PersonRegistrationInfosContent from 'pages/private/Executions/components/Tabs/components/BasicInfos/components/Person/RegistrationInfosContent';
import CompanyRegistrationInfosContent from 'pages/private/Executions/components/Tabs/components/BasicInfos/components/Company/RegistrationInfosContent';

const DocumentRegistrationInfos = ({ data, personId }) => {
  return (
    <div className="to-print-registration-infos">
      {personId ? (
        <PersonRegistrationInfosContent person={data} toPrint />
      ) : (
        <CompanyRegistrationInfosContent company={data} toPrint />
      )}
    </div>
  );
};

DocumentRegistrationInfos.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  personId: PropTypes.number,
};

DocumentRegistrationInfos.defaultProps = {
  personId: null,
};

export default DocumentRegistrationInfos;
