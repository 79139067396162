import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

const I18N_BASE_PATH =
  'pages.private.profiles.components.tabs.components.documentsValidations.components.verifiedIdentity.index.documentLiveness';

function DocumentLivenessInfos({ information }) {
  const { t: translate } = useTranslation();
  const {
    isPhotocopy,
    confidence,
    reliabilityThreshold,
    reliablePrediction,
  } = information;

  const reliabilityDecimalsLength = () => {
    if (Number.isInteger(reliabilityThreshold)) {
      return 0;
    }

    return reliabilityThreshold?.toString().split('.')[1]?.length - 2;
  };

  const resultIfReliabilityHasNoDecimals =
    reliabilityDecimalsLength() <= 2 ? 2 : reliabilityDecimalsLength();

  const returnPercent = (param, decimalNumber) =>
    Number(param || 0)
      .toFixed(2)
      .toString() === '1.00'
      ? `${100}%`
      : `${Number(param * 100 || 0).toFixed(decimalNumber)}%`;

  const resultBooleanIsPhotocopy = isPhotocopy
    ? translate(`${I18N_BASE_PATH}.columns.yes`)
    : translate(`${I18N_BASE_PATH}.columns.no`);

  return (
    <>
      <Row className="custom-validations-execution">
        <TableInfoCard
          span={8}
          title={translate(`${I18N_BASE_PATH}.columns.isPhotocopy`)}
          content={
            reliablePrediction
              ? resultBooleanIsPhotocopy
              : translate(`${I18N_BASE_PATH}.columns.unreliablePrediction`)
          }
          bordered={false}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_BASE_PATH}.columns.reliabilityThreshold`)}
          content={returnPercent(reliabilityThreshold, resultIfReliabilityHasNoDecimals)}
          bordered={false}
        />
        <TableInfoCard
          span={8}
          title={translate(`${I18N_BASE_PATH}.columns.confidence`)}
          content={returnPercent(confidence, resultIfReliabilityHasNoDecimals)}
          bordered={false}
        />
      </Row>
    </>
  );
}

const Props = {
  isPhotocopy: PropTypes.bool.isRequired,
  saturation: PropTypes.number.isRequired,
  reliabilityThreshold: PropTypes.number.isRequired,
  confidence: PropTypes.number.isRequired,
};

DocumentLivenessInfos.propTypes = {
  information: PropTypes.shape(Props).isRequired,
};

export default DocumentLivenessInfos;
