import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SummaryPrint } from 'pages/private/KYBCompany/CompanyDetailsV2/components/TabsComponent/Summary/Summary';
import CompanyInfo from 'pages/private/KYBCompany/CompanyDetailsV2/components/TabsComponent/CompanyInfo';
import RecordsPrint from './components/RecordsPrint';
import CompanyPrintHeader from './components/Header';
import InputDataPrint from './components/InputDataPrint';
import { CompanySubtitle1 } from '../../../CompanyDetailsV2/components';
import './styles.less';
import RiskFactors from '../../../CompanyDetailsV2/components/TabsComponent/RiskFactors';
import Comments from '../../../CompanyDetailsV2/components/TabsComponent/Comments';
import GroupStructure from '../../../CompanyDetailsV2/components/TabsComponent/GroupStructure';
import People from '../TabsComponent/People';
import FinancialInfo from '../TabsComponent/FinancialInfo';

const TABS = 'pages.private.KYBCompany.pages.companyDetails.tabs';

const CompanyPrint = forwardRef(
  (
    {
      transactionId,
      createdAt,
      executionStatus,
      companyInfo,
      riskFactors,
      comments,
      summary,
      financial,
      people,
      companyLinks,
      records,
      inputData,
      itemsToExport,
      setLoading,
      summaryDisabledMap,
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <div ref={ref} id="company-to-print">
        <style>
          {`
            @media print {
              @page {
                size: A4 portrait !important;
                margin: 18mm 8mm 12mm 8mm !important;
              }
            }
          `}
        </style>
        <CompanyPrintHeader
          companyName={summary.title}
          identifier={summary.identifier}
          executionStatus={executionStatus}
          createdAt={createdAt}
        />
        <CompanySubtitle1
          level={2}
          className="kyb-company-print-section-title"
          render={itemsToExport.summary.value}
        >
          {t(`${TABS}.summary`, 'Summary')}
        </CompanySubtitle1>
        <SummaryPrint
          className="kyb-company-print-summary"
          summary={summary}
          records={records}
          companyInfo={companyInfo}
          disabled={summaryDisabledMap}
          itemsToExport={itemsToExport.summary}
        />
        <CompanyInfo
          print
          payload={companyInfo}
          itemsToExport={itemsToExport.companyInfo}
          className="kyb-company-print-info"
        />
        <CompanySubtitle1
          level={2}
          className="kyb-company-print-section-title"
          render={itemsToExport.financial.value}
        >
          {t(`${TABS}.financial`, 'Financial')}
        </CompanySubtitle1>
        <FinancialInfo
          financialData={financial}
          itemsToExport={itemsToExport.financial}
          print
        />
        <CompanySubtitle1
          level={2}
          className="kyb-company-print-section-title"
          render={itemsToExport.comments.value}
        >
          {t(`${TABS}.comments`, 'Comments')}
        </CompanySubtitle1>
        <Comments comments={comments} itemsToExport={itemsToExport.comments} print />
        <CompanySubtitle1
          level={2}
          className="kyb-company-print-section-title"
          render={itemsToExport.riskFactors.value}
        >
          {t(`${TABS}.riskFactors`, 'Risk factors')}
        </CompanySubtitle1>
        <RiskFactors
          payload={riskFactors}
          itemsToExport={itemsToExport.riskFactors}
          print
        />
        <CompanySubtitle1
          level={2}
          className="kyb-company-print-section-title"
          render={itemsToExport.people.value}
        >
          {t(`${TABS}.people`, 'People')}
        </CompanySubtitle1>
        <People peopleData={people} itemsToExport={itemsToExport.people} print />
        <CompanySubtitle1
          level={2}
          className="kyb-company-print-section-title"
          render={itemsToExport.groupStructure.value}
        >
          {t(`${TABS}.groupStructure`, 'Group structure')}
        </CompanySubtitle1>
        <GroupStructure
          payload={companyLinks}
          itemsToExport={itemsToExport.groupStructure}
          print
        />
        <CompanySubtitle1
          level={2}
          className="kyb-company-print-section-title"
          render={itemsToExport.records.value}
        >
          {t(`${TABS}.records`, 'Records')}
        </CompanySubtitle1>
        <RecordsPrint
          transactionId={transactionId}
          companyName={summary.companyName}
          data={records}
          itemsToExport={itemsToExport.records}
          setLoading={setLoading}
          print
        />
        <CompanySubtitle1
          level={2}
          className="kyb-company-print-section-title"
          render={itemsToExport.inputData.value}
        >
          {t(`${TABS}.inputData`, 'Input data')}
        </CompanySubtitle1>
        <InputDataPrint data={inputData} itemsToExport={itemsToExport.inputData} print />
      </div>
    );
  }
);

export default CompanyPrint;

CompanyPrint.propTypes = {
  createdAt: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  executionStatus: PropTypes.any.isRequired,
  companyInfo: PropTypes.any.isRequired,
  riskFactors: PropTypes.array.isRequired,
  comments: PropTypes.array.isRequired,
  summary: PropTypes.any.isRequired,
  financial: PropTypes.any.isRequired,
  people: PropTypes.any.isRequired,
  companyLinks: PropTypes.any.isRequired,
  records: PropTypes.any.isRequired,
  inputData: PropTypes.any.isRequired,
  itemsToExport: PropTypes.any.isRequired,
  setLoading: PropTypes.func.isRequired,
  summaryDisabledMap: PropTypes.object.isRequired,
};
