import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router-dom';
import { Form, Button, Spin, message } from 'antd';

import { useFetch } from 'services/hooks';
import { CardWrapper } from 'components/CardWrapper';

import managementPoliciesKeys from 'utils/managementPoliciesKeys';
import trustPoliciesKeys from 'utils/trustPoliciesKeys';
import Wrapper from '../../wrapper';

import NewGroup from './components/NewGroup';

const permissionsActions = ['read', 'update', 'create', 'delete'];

const GroupsCreate = () => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();

  const history = useHistory();
  const { id: groupId } = useParams();
  const { get, loading, data } = useFetch();
  const { post, put } = useFetch();
  const [loadingFetch, setLoadingFetch] = useState(false);
  const handleInvite = useCallback(
    async (payload) => {
      setLoadingFetch(true);
      const newPoliciesPayload = [];

      const specialKeys = Object.keys(trustPoliciesKeys.special);

      trustPoliciesKeys.normal?.forEach((key) => {
        if (
          !payload[key]?.read &&
          !payload[key]?.create &&
          !payload[key]?.update &&
          !payload[key]?.delete
        ) {
          return;
        }

        newPoliciesPayload.push({
          name: key,
          effect: 'Allow',
          actions: permissionsActions
            .map((perm) => (payload[key][perm] ? `${key}:${perm}` : null))
            .filter((v) => v !== null),
          resources: [...(payload[key].resources || []), '*'],
          special: false,
          product: 'trust',
        });
      });

      const setExecutionsIds = () => {
        const executionsIds = newPoliciesPayload
          ?.map((item) => {
            if (item?.name === 'profile-templates') {
              return item?.resources?.filter((el) => el.startsWith('executions:'));
            }
          })
          .filter((item) => {
            return item !== undefined;
          });

        newPoliciesPayload?.map((item) => {
          if (item?.name === 'executions') {
            const all = executionsIds[0]?.concat(item?.resources);
            item.resources = all;
          }
        });
      };

      managementPoliciesKeys.normal?.forEach((key) => {
        if (
          !payload[key]?.read &&
          !payload[key]?.create &&
          !payload[key]?.update &&
          !payload[key]?.delete
        ) {
          return;
        }

        newPoliciesPayload.push({
          name: key,
          effect: 'Allow',
          actions: permissionsActions
            .map((perm) => (payload[key][perm] ? `${key}:${perm}` : null))
            .filter((v) => v !== null),
          resources: [...(payload[key].resources || []), '*'],
          special: false,
          product: 'management',
        });

        newPoliciesPayload?.forEach((policy) => {
          policy?.actions?.forEach(
            (perm) => perm === 'users:update' && policy?.actions?.push('groups:create')
          );
        });
      });

      specialKeys.forEach((key) => {
        if (!payload['special-rules'] || !payload['special-rules'][key]) return;

        newPoliciesPayload.push({
          ...trustPoliciesKeys.special[key],
          name: key,
          product: 'trust',
        });
      });

      setExecutionsIds();

      try {
        if (groupId) {
          await put({
            url: `/groups/${groupId}`,
            payload: {
              name: payload?.name,
              policies: newPoliciesPayload,
            },
            showError: true,
          });
          message.success(
            translate(
              'pages.private.groupsManagement.groupsCreate.index.messageSuccessEdit'
            )
          );
        } else {
          await post({
            url: '/groups',
            payload: {
              name: payload?.name,
              policies: newPoliciesPayload,
            },
            showError: true,
          });
          message.success(
            translate(
              'pages.private.groupsManagement.groupsCreate.index.messageSuccessCreate'
            )
          );
        }
        history.push('/groups');
      } finally {
        setLoadingFetch(false);
      }
    },
    [groupId, history, post, put, translate]
  );

  const setFormValues = useCallback(
    (dataForm) => {
      if (!dataForm) return;

      const formValues = {
        name: dataForm?.name,
        icon: dataForm?.icon,
        product: dataForm?.product,
      };

      dataForm?.policies?.forEach((policy) => {
        if (!policy.special) {
          formValues[policy.name] = {
            execute: policy.actions.includes(`${policy.name}:execute`),
            read: policy.actions.includes(`${policy.name}:read`),
            create: policy.actions.includes(`${policy.name}:create`),
            delete: policy.actions.includes(`${policy.name}:delete`),
            update: policy.actions.includes(`${policy.name}:update`),
            resources: policy.resources?.filter((resource) => resource !== '*'),
          };
        } else {
          formValues['special-rules'] = {
            ...formValues['special-rules'],
            [policy.name]: true,
          };
        }
      });
      form.setFieldsValue(formValues);
    },
    [form]
  );

  const getGroup = useCallback(async () => {
    const response = await get({
      url: `/groups/${groupId}`,
      config: {},
    });
    setFormValues(response?.data);
  }, [get, groupId, setFormValues]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    if (groupId && !data) {
      getGroup();
    }
  }, [data, getGroup, groupId]);
  return (
    <>
      <Wrapper id="groups-create">
        <CardWrapper hasPadding>
          <Form
            layout="vertical"
            form={form}
            onFinish={(value) => {
              handleInvite(value);
            }}
          >
            {loading ? (
              <Spin className="flex center mrg-vertical-50 mrg-horizon-auto" />
            ) : (
              <NewGroup formRef={form} />
            )}

            <div className="flex space-between">
              <Button type="secundary" onClick={() => history.push('/groups')}>
                {translate(
                  'pages.private.groupsManagement.groupsCreate.index.buttonBack'
                )}
              </Button>

              <Button type="primary" htmlType="submit" loading={loadingFetch}>
                {translate(
                  'pages.private.groupsManagement.groupsCreate.index.buttonConfirm'
                )}
              </Button>
            </div>
          </Form>
        </CardWrapper>
      </Wrapper>
    </>
  );
};

export default GroupsCreate;
