import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useAuth } from 'hooks/auth';

import Wrapper from 'pages/private/wrapper';
import { CardWrapper } from 'components/CardWrapper';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { Person, UserActive } from '@combateafraude/icons/users';
import _ from 'lodash';

import List from './components/List';
import SuggestionsScreen from './components/SuggestionsScreen';

import Unauthorized from '../Unauthorized';

import './styles.less';

const { TabPane } = Tabs;

const GlobalProfiles = () => {
  const { user } = useAuth();
  const { t: translate } = useTranslation();

  return !hasFeatureFlag('profilesModule', user) ? (
    <Unauthorized />
  ) : (
    <Wrapper id="global-profiles-list">
      <div className="profile-content-grid">
        <div className="profile-content-grid-1">
          <CardWrapper hasPadding>
            <List segment="person" />
            {/* <Tabs defaultActiveKey="1">
              <TabPane
                key="1"
                tab={
                  <div className="flex start-center">
                    <Person className="font-size-18 mrg-right-5" />
                    {translate(
                      'pages.private.globalProfiles.index.profilesHistory',
                      'Profiles history'
                    )}
                  </div>
                }
              >
                <List segment="person" />
              </TabPane>
              <TabPane
                key="2"
                tab={
                  <div className="flex start-center">
                    <UserActive className="font-size-18 mrg-right-5" />
                    {translate(
                      'pages.private.globalProfiles.index.connectExecutions',
                      'Connect executions'
                    )}
                  </div>
                }
              >
                <SuggestionsScreen />
              </TabPane>
            </Tabs> */}
          </CardWrapper>
        </div>
      </div>
    </Wrapper>
  );
};

export default GlobalProfiles;
