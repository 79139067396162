import React, { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Button, Form, Tooltip } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';

import PageTitle from 'components/PageTitle';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';

// import { useFetch } from 'services/hooks';

import PermissionWrapper from 'routes/PermissionWrapper';

import SearchInput from 'components/Form/SearchInput';
import useDynamoTable from 'components/List/hooks/useDynamoTable';

import { isGroupAdmin } from 'utils/translations';
import ActionsList from './components/GroupsActionsList';

// import { Container } from './styles';

const colors = ['#87d068', '#004af7', '#f56a00'];

const GroupsList = () => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const history = useHistory();
  const refreshListRef = useRef(() => {});
  // const { get, loading } = useFetch();
  // const [policies, setPolicies] = useState([]);
  const [query] = useQueryParams({
    search: StringParam,
    group: StringParam,
  });

  const selectColorIndex = (i) => {
    let index = i;
    if (index > colors.length - 1) {
      index = selectColorIndex(index - colors.length);
    }
    return index;
  };

  /* const findPolicy = useCallback(
    (id) => {
      const policy = policies?.find((p) => p?.id === id);
      return (
        <>
          {policy ? (
            <div>
              {policy?.icon && <i className={`${policy?.icon} mrg-right-10`} />}
              <span>{policy?.name}</span>
            </div>
          ) : (
            <div>
              <i className="caf-ic_x_close mrg-right-10" />
              <span>
                {translate(
                  'pages.private.users.components.groupsList.index.findPolicy.noAccess'
                )}
              </span>
            </div>
          )}
        </>
      );
    },
    [policies, translate]
  ); */

  const columns = [
    {
      key: 'name',
      title: (
        <span className="column-title">
          {translate('pages.private.users.components.groupsList.index.columns.name')}
        </span>
      ),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (__, record) => (
        <Link
          disabled={isGroupAdmin(record?.id)}
          to={`/groups/${record?.id}/edit`}
          className="flex align-center text-dark"
        >
          {isGroupAdmin(record?.id)
            ? translate('global.hooks.group.getListGroups.admin')
            : record?.name}
        </Link>
      ),
    },
    {
      key: 'createdAt',
      title: (
        <span className="column-title">
          {translate('pages.private.users.components.groupsList.index.columns.createdAt')}
        </span>
      ),
      dataIndex: 'createdAt',
      sorter: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      render: (__, record) => i18nFormatDate(record?.updatedAt || record?.createdAt, 1),
    },
    /* {
      key: 'management',
      title: <span className="column-title">Management</span>,
      dataIndex: 'management',
      render: (__, record) => !loading && findPolicy(record?.policyGroups?.management),
    }, */
    {
      key: 'actions',
      render: (__, record) => {
        if (isGroupAdmin(record.id)) {
          return <></>;
        }
        return <ActionsList group={record} refreshListRef={refreshListRef} />;
      },
      width: 60,
    },
  ];

  const { tableContent, refreshList, setFilterData } = useDynamoTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_TRUST_API}/groups`,
      config: {
        params: {
          defaultPolicies: true,
          // limit: 2,
        },
      },
    },
    queryParams: {
      search: StringParam,
      group: StringParam,
    },
    columns,
    // customLoading: loading,
    rowKey: 'id',
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        search: values._search ? values._search.trim() : undefined,
        group: values.group || undefined,
      });
    }, 500),
    []
  );

  /* const getPolicies = useCallback(async () => {
    try {
      const [respTrust, respManagement] = await Promise.all([
        get({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/policy-groups`,
          config: {
            params: {
              product: 'trust',
              defaultPolicies: true,
            },
          },
        }),
        await get({
          url: `${process.env.REACT_APP_BASE_URL_AUTH_API}/policy-groups`,
          config: {
            params: {
              product: 'management',
              defaultPolicies: true,
            },
          },
        }),
      ]);
      setPolicies([...respTrust?.docs, ...respManagement?.docs]);
    } catch {
      message.error(
        translate(
          'pages.private.users.components.groupsList.index.getPolicies.messageError'
        )
      );
    }
  }, [get, translate]);

  useEffect(() => {
    getPolicies();
  }, [getPolicies]); */

  return (
    <>
      <div className="flex align-center gx-w-100 space-between">
        <PageTitle
          title={translate(
            'pages.private.users.components.groupsList.index.pageTitle.title'
          )}
          subtitle={translate(
            'pages.private.users.components.groupsList.index.pageTitle.subtitle'
          )}
        />
        <div className="flex row mrg-top-15 mrg-btm-30 align-center">
          <Form
            layout="horizontal"
            className="flex align-center"
            onValuesChange={handleFormChange}
            initialValues={{ search: query?.search, group: query?.group }}
          >
            <SearchInput
              placeholder={translate(
                'pages.private.users.components.groupsList.index.searchInput'
              )}
            />
          </Form>
          <PermissionWrapper
            requiredPermissions={['permissions:create']}
            product="management"
          >
            <div className="mrg-left-15">
              <Button
                type="primary"
                ghost
                className="flex center"
                onClick={() => history.push('/groups/create')}
              >
                <i className="caf-ic_plus font-size-15 mrg-right-5" />
                <span>
                  {translate('pages.private.users.components.groupsList.index.newGroup')}
                </span>
              </Button>
            </div>
          </PermissionWrapper>
        </div>
      </div>
      <div className="gx-w-100 mrg-top-10">{tableContent}</div>
    </>
  );
};

export default GroupsList;
