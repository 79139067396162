import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tooltip } from 'antd';
import { Info } from '@combateafraude/icons/general';

import { useExecution } from 'hooks/execution';

import EmptyMessage from 'components/EmptyMessage';

import { Combateafraude } from '@combateafraude/icons/security';
import {
  ExclamationStroke,
  IcPauseSmall,
  CorrectStroke,
  ErroStroke,
} from '@combateafraude/icons/others';

import DossierCard from '../../../DossierCard';
import ComplianceRuleDetails from './components/ComplianceRuleDetails';
import { statusColor } from './utils/statusColor';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.executions.components.tabs.components.complianceRules.index.complianceRules';

const availableRulesTooltip = [
  '6512f60597d0e53635f0683a',
  '6512f60597d0e53635f0683b',
  '6530a88f23ef4b0d402f9cf9',
  '655b6593fb1c953d25a7d216',
  '655b6655fb1c953d25a7d217',
  '6530a88e23ef4b0d402f9cf8',
  '6512f60597d0e53635f06839',
  '6512f60597d0e53635f06838',
];

const statusIcon = (status, color) => {
  switch (status) {
    case 'APPROVED':
    case 'APROVADO':
    case 'VALID':
      return <CorrectStroke color={color} fontSize={12} style={{ marginRight: '3px' }} />;
    case 'REPROVADO':
    case 'REPROVED':
      return <ErroStroke color={color} fontSize={12} style={{ marginRight: '3px' }} />;
    case 'PENDENTE':
    case 'PENDING':
    case 'PENDING_OCR':
      return <IcPauseSmall color={color} fontSize={12} style={{ marginRight: '3px' }} />;
    case 'WARNING':
      return (
        <ExclamationStroke color={color} fontSize={12} style={{ marginRight: '3px' }} />
      );

    default:
      return <></>;
  }
};

const DocumentsValidations = () => {
  const { t: translate, i18n } = useTranslation();

  const { execution } = useExecution();

  const translateStatus = useCallback((status) => {
    switch (status) {
      case 'APPROVED':
      case 'VALID':
        return `${I18N_BASE_PATH}.translatedStatus.approved`;
      case 'REPROVED':
        return `${I18N_BASE_PATH}.translatedStatus.reproved`;
      case 'WARNING':
        return `${I18N_BASE_PATH}.translatedStatus.warning`;
      default:
        return `${I18N_BASE_PATH}.translatedStatus.pending`;
    }
  }, []);

  const validations = useMemo(() => {
    if (!execution?.validations) return null;

    return execution?.validations;
  }, [execution]);

  return (
    <DossierCard
      title={translate(`${I18N_BASE_PATH}.dossierCard.title`)}
      subtitle={translate(`${I18N_BASE_PATH}.dossierCard.subtitle`)}
      customId="compliance-rules-card"
      newIcon={<Combateafraude backgroundBlue fontSize={25} />}
      noCol
      noMarginLeft
    >
      {validations?.length > 0 &&
        validations?.map((v) => (
          <Row
            key={v.ruleId}
            align="middle"
            justify="center"
            className="compliance-rules-status reset-mrg no-pdd pdd-top-10 pdd-btm-10"
          >
            <Col span={16} className="reset-mrg no-pdd">
              <span
                className="rule-title"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {v.code || v.ruleId
                  ? translate(
                      `validations.${v.code || v.ruleId}.title`,
                      v.title || v.ruleName
                    )
                  : v.title || v.ruleName}
                {['score_check_between'].includes(v?.code) && (
                  <span className="mrg-left-10">
                    {v?.extraSettings?.minScore} e {v?.extraSettings?.maxScore}
                  </span>
                )}
                {['score_check_over'].includes(v?.code) && (
                  <span className="mrg-left-10">{v?.extraSettings?.overScore}</span>
                )}
                {['score_check_below'].includes(v?.code) && (
                  <span className="mrg-left-10">{v?.extraSettings?.belowScore}</span>
                )}
                {(i18n.exists(`validations.${v.ruleId}.tooltip`) || v?.description) && (
                  <Tooltip
                    title={
                      v?.description ||
                      translate(`validations.${v.ruleId}.tooltip`, v.ruleName)
                    }
                    color="#323232"
                    overlayInnerStyle={{ textAlign: 'left' }}
                    placement="top"
                  >
                    <Info
                      style={{ width: '16.5px', height: '16.5px', marginLeft: 4 }}
                      color="#323232"
                    />
                  </Tooltip>
                )}
              </span>
            </Col>
            <Col span={6}>
              <span
                className="tag-background"
                style={{
                  color: statusColor[v?.resultStatus || v?.status]?.color,
                  background: statusColor[v?.resultStatus || v?.status]?.background,
                }}
              >
                {statusIcon(
                  v?.resultStatus || v?.status,
                  statusColor[v?.resultStatus || v?.status]?.iconColor
                )}
                <span className="mrg-left-5">
                  {translate(translateStatus(v?.resultStatus || v?.status))}
                </span>
                <ComplianceRuleDetails execution={execution} validation={v} />
              </span>
            </Col>
          </Row>
        ))}

      {validations?.length <= 0 && (
        <EmptyMessage
          type="compliance"
          description={translate(`${I18N_BASE_PATH}.emptyMessage.description`)}
          withCard
        />
      )}
    </DossierCard>
  );
};

export default DocumentsValidations;
