/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form, Input } from 'antd';
import PageTitle from 'components/PageTitle';
import PoliciesSelect from './components/PoliciesPanels';

const NewGroup = ({ formRef, disabled }) => {
  const { id: groupId } = useParams();
  const { t: translate } = useTranslation();

  return (
    <div id="select-policies" className="width-100 ">
      <PageTitle
        title={
          !groupId
            ? translate(
                'pages.private.groupsManagement.groupsCreate.components.newGroup.index.titleNewGroup'
              )
            : translate(
                'pages.private.groupsManagement.groupsCreate.components.newGroup.index.titleEditGroup'
              )
        }
        subtitle={
          !groupId
            ? translate(
                'pages.private.groupsManagement.groupsCreate.components.newGroup.index.subtitleNewGroup'
              )
            : translate(
                'pages.private.groupsManagement.groupsCreate.components.newGroup.index.subtitleEditGroup'
              )
        }
      />
      <Form.Item
        name="name"
        label={translate(
          'pages.private.groupsManagement.groupsCreate.components.newGroup.index.name'
        )}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <PoliciesSelect formRef={formRef} disabled={disabled} />
    </div>
  );
};
export default NewGroup;
