/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import * as executionDataSources from 'utils/executionDataSource';
import SourcesList from './Lists/SourcesList';
import SourcesListCompany from './Lists/SourcesListPJ';
import OwnersList from './Lists/OwnersList';
import SuspiciousPlaces from './components/SuspiciousPlaces';
import DenyListPrivate from './components/denyListPrivate';
import PeopleInternalRestrictiveList from './components/PeopleInternalRestrictiveList';

import DataSourceCardCollapsePanel from '../DataSourceCardCollapsePanel';
import RelatedSports from './components/RelatedSports';

const RestrictiveLists = ({ toPrint, toPrintDetails }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const dataSources = useMemo(() => Object.keys(execution?.sections || {}) || [], [
    execution,
  ]);

  const showKycCompliance = useMemo(() => findSourceSection('pfKycCompliance'), [
    findSourceSection,
  ]);

  const sourcesListCompany = useMemo(() => findSourceSection('pjKycCompliance'), [
    findSourceSection,
  ]);

  const showKycComplianceOwners = useMemo(
    () =>
      findSourceSection('kycComplianceOwners') ||
      findSourceSection('pjKycComplianceOwners') ||
      findSourceSection('pfKycComplianceOwners'),
    [findSourceSection]
  );

  const showSuspiciousPlaces = useMemo(
    () =>
      dataSources.includes('suspicious_places') || execution?.sections?.suspiciousPlaces,
    [execution, dataSources]
  );

  const showPfDenyListPrivate = useMemo(() => findSourceSection('pfDenyListPrivate'), [
    findSourceSection,
  ]);

  const showPjDenyListPrivate = useMemo(() => findSourceSection('pjDenyListPrivate'), [
    findSourceSection,
  ]);
  const showPeopleInternalRestrictiveList = useMemo(
    () => findSourceSection('pfPersonalRelationshipsTrustRl'),
    [findSourceSection]
  );
  const peopleSportRelated = useMemo(() => findSourceSection('peopleSportRelated'), [
    findSourceSection,
  ]);
  return (
    <DataSourceCardCollapsePanel
      id="restrictive-lists"
      title={translate(
        'components.dataSourceCards.restrictiveLists.index.restrictiveLists.dossierCard.title'
      )}
      icon="caf-ic_lawier"
      toPrint={toPrint}
    >
      <>
        {showKycComplianceOwners ? (
          <div className="gx-w-100 no-mrg no-pdd">
            <OwnersList source={showKycComplianceOwners} toPrint={toPrint} />

            {showKycCompliance && (
              <SourcesList
                source={showKycCompliance}
                toPrint={toPrint}
                toPrintDetails={toPrintDetails}
              />
            )}
            {sourcesListCompany && (
              <SourcesListCompany
                source={sourcesListCompany}
                toPrint={toPrint}
                toPrintDetails={toPrintDetails}
              />
            )}
          </div>
        ) : (
          <div>
            {showKycCompliance && (
              <SourcesList
                personalInfo
                source={showKycCompliance}
                toPrint={toPrint}
                toPrintDetails={toPrintDetails}
              />
            )}
            {sourcesListCompany && (
              <SourcesListCompany
                source={sourcesListCompany}
                toPrint={toPrint}
                toPrintDetails={toPrintDetails}
              />
            )}
          </div>
        )}

        {showSuspiciousPlaces && (
          <div className="gx-w-100 no-mrg no-pdd">
            <SuspiciousPlaces data={showSuspiciousPlaces} toPrint={toPrint} />
          </div>
        )}

        {/* NÃO TRADUZIR O PARAMETRO TYPE */}
        {showPfDenyListPrivate && (
          <div className="gx-w-100 no-mrg no-pdd">
            <DenyListPrivate source={showPfDenyListPrivate} type="pf" toPrint={toPrint} />
          </div>
        )}

        {/* NÃO TRADUZIR O PARAMETRO TYPE */}
        {showPjDenyListPrivate && (
          <div className="gx-w-100 no-mrg no-pdd">
            <DenyListPrivate source={showPjDenyListPrivate} type="pj" toPrint={toPrint} />
          </div>
        )}
        {showPeopleInternalRestrictiveList && (
          <PeopleInternalRestrictiveList
            source={showPeopleInternalRestrictiveList}
            toPrint={toPrint}
          />
        )}
        {peopleSportRelated && (
          <RelatedSports source={peopleSportRelated} toPrint={toPrint} />
        )}
      </>
    </DataSourceCardCollapsePanel>
  );
};

export default RestrictiveLists;
