import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, notification, message, Menu, Dropdown, Col, Spin } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';
import { useQueryParams, StringParam, withDefault, ArrayParam } from 'use-query-params';
import _ from 'lodash';
import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

import { useReports } from 'hooks/reports';
import { useProfileTemplate } from 'hooks/profileTemplate';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import { useComponentNextList } from 'components/List';
import { useWorkflow } from 'hooks/useWorkflow';

import PermissionWrapper from 'routes/PermissionWrapper';

import ModalIproovNotification from 'components/ModalIproovNotification';
import { SortSelect } from './components/SortSelectCustom';
import ExecutionCard from './components/ExecutionCard';
import EmptyPage from './components/EmptyPage';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.executions.components.list.index';

const ExecutionsList = () => {
  const { t: translate } = useTranslation();
  const [socket, setSocket] = useState(null);
  const { user } = useAuth();

  const [exportStatus, setExportStatus] = useState({
    loading: false,
    error: false,
  });

  const { get } = useFetch();
  const { loadAllReports, loadingAllReports } = useReports();
  const [isDropdownExportOpened, setIsDropdownExportOpened] = useState(false);

  const {
    allProfileTemplates,
    loadProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  const { data: allWorkflows, getWorkflowsData } = useWorkflow();

  const {
    data,
    componentList,
    updateParams,
    getListFiveTimes,
    allData,
    loadingFivetimes,
    loading,
    paginationLabel,
    next,
  } = useComponentNextList({
    component: ExecutionCard,
    rowKey: '_id',
    url: 'executions',
    customLoader: loadingAllReports || loadingAllProfileTemplates,
    socket,
    componentProps: {
      allProfileTemplates,
      allWorkflows,
    },
  });

  useEffect(() => {
    if (socket !== null) socket.close();

    if (data?.length > 0) {
      const newSocket = new WebSocket(process.env.REACT_APP_SOCKET_URL);

      newSocket.addEventListener('open', () => {
        const executionsIds = data.map((execution) => execution._id || execution.id);

        if (executionsIds) {
          const topics = executionsIds.map((id) => `trust-execution-${id}`);

          newSocket.send(
            JSON.stringify({
              action: 'subscribeToTopic',
              topics,
            })
          );
        }
      });

      setSocket(newSocket);
    }

    return () => {
      if (socket !== null) socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [query] = useQueryParams({
    updatedDate: StringParam,
    createdDate: StringParam,
    search: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
    templateIds: withDefault(ArrayParam, []),
    reports: withDefault(ArrayParam, []),
    customStatus: withDefault(ArrayParam, []),
    documentscopy: withDefault(ArrayParam, []),
    validations: withDefault(ArrayParam, []),
    fraud: StringParam,
    recurrence: StringParam,
    _order: StringParam,
    _sort: StringParam,
    _type: StringParam,
    _internalRestrictiveList: StringParam,
  });

  const querySnapshot = useRef({});

  const mapFilters = useCallback(() => {
    const filters = {};

    if (hasFeatureFlag('isWorkflowBuilder', user)) {
      filters.segment = 'person';
    }

    if (query.updatedDate) {
      switch (query.updatedDate) {
        case 'last24Hours':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'last7Days':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'last30Days':
          filters._startUpdatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
          filters._endUpdatedDate = new Date().getTime();
          break;
        case 'lastYear':
          filters._startUpdatedDate = new Date().setFullYear(
            new Date().getFullYear() - 1
          );
          filters._endUpdatedDate = new Date().getTime();
          break;
        default:
          if (query.updatedDate.substr(0, 6) === 'custom') {
            const [startDate, endDate] = query.updatedDate.substr(7).split('-');
            filters._startUpdatedDate = startDate;
            filters._endUpdatedDate = endDate;
          }
          break;
      }
    }

    if (query.createdDate) {
      switch (query.createdDate) {
        case 'last24Hours':
          filters._startCreatedDate = new Date().getTime() - 24 * 60 * 60 * 1000;
          filters._endCreatedDate = new Date().getTime();
          break;
        case 'last7Days':
          filters._startCreatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
          filters._endCreatedDate = new Date().getTime();
          break;
        case 'last30Days':
          filters._startCreatedDate = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
          filters._endCreatedDate = new Date().getTime();
          break;
        case 'lastYear':
          filters._startCreatedDate = new Date().setFullYear(
            new Date().getFullYear() - 1
          );
          filters._endCreatedDate = new Date().getTime();
          break;
        default:
          if (query.createdDate.substr(0, 6) === 'custom') {
            const [startDate, endDate] = query.createdDate.substr(7).split('-');
            filters._startCreatedDate = startDate;
            filters._endCreatedDate = endDate;
          }
          break;
      }
    } else {
      filters._startCreatedDate = new Date().setFullYear(new Date().getFullYear() - 1);
      filters._endCreatedDate = new Date().getTime();
    }

    if (query.search && !_.isEmpty(query.search)) {
      filters._search = query.search.map((s) => s.replace(/[^0-9\w ]/g, ''))?.join('|');
    }

    if (query._sort) {
      filters._sort = query._sort;
    }

    if (query.status && !_.isEmpty(query.status)) {
      filters.status = query.status.join('|');
    }

    if (query.documentscopy && !_.isEmpty(query.documentscopy)) {
      filters._documentscopyState = query.documentscopy.join('|');
    }

    if (query.fraud && !_.isEmpty(query.fraud)) {
      filters.fraud = query.fraud;
    }

    if (query.recurrence && !_.isEmpty(query.recurrence)) {
      filters.recurrence = query.recurrence;
    }

    if (query.validations && !_.isEmpty(query.validations)) {
      if (query.validations.includes('facematchEqual')) {
        filters._facematchEqual = false;
      }

      if (query.validations.includes('documentReadable')) {
        filters._documentReadable = false;
      }

      if (query.validations.includes('hasProcessesLinked')) {
        filters._hasProcessesLinked = false;
      }
    }

    if (query._internalRestrictiveList) {
      filters._internalRestrictiveList = query._internalRestrictiveList;
    }

    if (query.customStatus && !_.isEmpty(query.customStatus)) {
      filters._customStatus = query.customStatus.join('|');
    }

    if (query.reports && !_.isEmpty(query.reports)) {
      filters._reports = query.reports.join('|');
    }

    if (query.templateIds && !_.isEmpty(query.templateIds)) {
      filters._templateIds = query.templateIds.join('|');
    }

    return filters;
  }, [query]);

  useEffect(() => {
    // Não recarregar quando filtro de 'Intervalo personalizado' for selecionado (updatedDate === 'custom')
    const changeUpdatedDate =
      (querySnapshot.current.updatedDate !== query.updatedDate &&
        query.updatedDate === 'custom') ||
      (querySnapshot.current.createdDate !== query.createdDate &&
        query.createdDate === 'custom');

    if (_.isEqual(querySnapshot.current, query)) return;
    querySnapshot.current = query;

    if (!changeUpdatedDate) {
      updateParams({
        url: 'executions',
        config: {
          params: { ...mapFilters() },
        },
      });
    }
  }, [query]); // eslint-disable-line

  useEffect(() => {
    loadAllReports();
  }, [loadAllReports]);

  useEffect(() => {
    loadProfileTemplates({
      active: 'all',
    });
    getWorkflowsData({ tenantId: user?.tenantId, _limit: 1000 });
  }, [getWorkflowsData, loadProfileTemplates, user.tenantId]);

  const parsedListContent = useMemo(() => {
    // if (loadingAllReports) return <Spin />;
    if (allData?.docs?.length === 0 && !loading && !loadingFivetimes)
      return <EmptyPage />;
    return componentList;
  }, [allData, loading, loadingFivetimes, componentList]);

  const onExportList = useCallback(
    async ({ type }) => {
      let _exportType;

      if (type === 'processes') _exportType = 'processes';

      try {
        setExportStatus({ loading: true, error: false });

        await get({
          url: `/executions/export`,
          config: {
            params: {
              ...mapFilters(),
              _exportType,
              _additionalFields: user.accountData.featureFlags?.additionalFieldsCsvExtraction?.fields?.join(
                ','
              ),
            },
          },
        });

        const notificationArgs = {
          type: 'success',
          message: translate(`${I18N_BASE_PATH}.onExportList.notificationArgs.message`),
          description: translate(
            `${I18N_BASE_PATH}.onExportList.notificationArgs.description`
          ),
          duration: 4.5,
          className: 'custom-notification',
        };

        notification.open(notificationArgs);
        setExportStatus({ loading: false, error: false });
      } catch (error) {
        message.error(translate(`${I18N_BASE_PATH}.onExportList.messages.error`));
        setExportStatus({ loading: false, error: true });
      }
    },
    [get, mapFilters, translate]
  );

  const menuExport = useMemo(
    () => (
      <>
        <Menu className="menu-export-list">
          <Menu.Item key="csv_profiles">
            <button
              type="button"
              disabled={!data?.length > 0 || exportStatus.loading}
              className="menu-export-list-btn reset-btn"
              onClick={() => onExportList({ type: 'executions' })}
            >
              <i className="caf-ic_c_download" />
              <span>{translate(`${I18N_BASE_PATH}.menuExport.title.executions`)}</span>
            </button>
          </Menu.Item>
        </Menu>
        <Menu className="menu-export-list">
          <Menu.Item key="csv_profiles">
            <button
              type="button"
              disabled={!data?.length > 0 || exportStatus.loading}
              className="menu-export-list-btn reset-btn"
              onClick={() => onExportList({ type: 'processes' })}
            >
              <i className="caf-ic_c_download" />
              <span>{translate(`${I18N_BASE_PATH}.menuExport.title.lawsuits`)}</span>
            </button>
          </Menu.Item>
        </Menu>
      </>
    ),
    [data, exportStatus, onExportList, translate]
  );

  return (
    <>
      {/* // ! modal temporário para notificação IPROOV */}
      <ModalIproovNotification />
      <div id="executions-list-component">
        <Row align="middle" justify="space-between" className="no-mrg">
          <Col className="list-header-left" span={16}>
            <PageTitle
              className="no-mrg"
              title={
                hasFeatureFlag('isWorkflowBuilder', user)
                  ? translate(`${I18N_BASE_PATH}.list.pageTitle.title.person`)
                  : translate(`${I18N_BASE_PATH}.list.pageTitle.title`)
              }
              subtitle={
                allData?.docs?.length === 0 && !loading && !loadingFivetimes
                  ? ''
                  : paginationLabel
              }
            />

            {next && !loading && !loadingFivetimes && (
              <PermissionWrapper requiredPermissions={['executions:read']}>
                <Button
                  type="default"
                  outlined
                  loading={loading || loadingFivetimes}
                  disabled={!next || loading || loadingFivetimes}
                  className="flex center load-more-btn"
                  onClick={getListFiveTimes}
                >
                  <i className="font-size-14 pdd-right-5" />
                  {translate(`${I18N_BASE_PATH}.list.loadMore`, 'Load more')}
                </Button>
              </PermissionWrapper>
            )}

            {(loading || loadingFivetimes) && <Spin />}
          </Col>
          <Col>
            <div className="flex mrg-btm-15">
              <SortSelect className="mrg-right-5" />
              <OutsideClickHandler
                onOutsideClick={() => setIsDropdownExportOpened(false)}
                disabled={!isDropdownExportOpened}
              >
                <Dropdown
                  overlay={menuExport}
                  trigger={['click']}
                  visible={isDropdownExportOpened}
                  placement="bottomRight"
                >
                  <Button
                    type="default"
                    shape="circle"
                    // loading={exportStatus.loading}
                    className={`export-list-btn ${exportStatus.error && 'danger'}`}
                    onClick={() => setIsDropdownExportOpened((oldState) => !oldState)}
                  >
                    {!exportStatus.loading && <i className="caf-ic_c_download" />}
                  </Button>
                </Dropdown>
              </OutsideClickHandler>
            </div>
          </Col>
        </Row>
        {parsedListContent}
      </div>
    </>
  );
};

export default ExecutionsList;
