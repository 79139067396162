import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal, Form, Input } from 'antd';
import { useFetch } from 'services/hooks';
import InputMask from 'components/Form/InputMask';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';

const I18N_BASE_PATH =
  'pages.private.restrictions.denyList.components.RestrictionCreateModal.index';

const I18N_ALT_PATH =
  'pages.private.restrictions.denyList.components.ImportCSVModal.index';

const RestrictionCreate = ({ refreshList, isEdit, docs, inProfile }) => {
  const { t: translate } = useTranslation();

  const [form] = Form.useForm();
  const { post, put, loading } = useFetch();
  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [inputMask, setInputMask] = useState('999.999.999-999');

  const identificationMask = useCallback(() => {
    if (form?.getFieldsValue()?.identification?.length > 14) {
      setInputMask('99.999.999/9999-99');
    } else setInputMask('999.999.999-999');
  }, [form]);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    setTimeout(() => {
      form.resetFields();
      setPage(0);
    }, 500);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    await form.validateFields();

    const { name, identification, reason } = form.getFieldsValue();

    if (isEdit) {
      await put({
        url: `/deny-list/${docs?._id}`,
        payload: {
          name,
          identification: identification?.replace(/\D/g, ''),
          reason,
        },
      });
    } else {
      await post({
        url: `/deny-list`,
        payload: {
          name,
          identification: identification?.replace(/\D/g, ''),
          reason,
        },
      });
    }

    if (refreshList?.current) refreshList.current();
    closeModal();
  }, [closeModal, docs, form, isEdit, post, put, refreshList]);

  const formContent = useMemo(
    () =>
      page === 0 && (
        <Row>
          <Col span={24}>
            {inProfile ? (
              <PageTitle
                title={
                  docs?.type === 'PF'
                    ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.addCpf`)
                    : translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.addCnpj`)
                }
                subtitle={
                  docs?.type === 'PF'
                    ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.addCpf`)
                    : translate(
                        `${I18N_BASE_PATH}.formContent.pageTitle.subtitle.addCnpj`
                      )
                }
              />
            ) : (
              <PageTitle
                title={
                  isEdit
                    ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.edit`)
                    : translate(`${I18N_BASE_PATH}.formContent.pageTitle.title.create`)
                }
                subtitle={
                  isEdit
                    ? translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.edit`)
                    : translate(`${I18N_BASE_PATH}.formContent.pageTitle.subtitle.create`)
                }
              />
            )}
          </Col>

          <Col span={24} className="mrg-vertical-20">
            <Form
              form={form}
              layout="vertical"
              // onFinish={handleSubmit}
              autoComplete="off"
              onChange={identificationMask}
            >
              <Form.Item
                initialValue={docs?.identification}
                label={translate(
                  `${I18N_BASE_PATH}.formContent.form.columnIdentification.label`
                )}
                name="identification"
                rules={[{ required: true }]}
              >
                <InputMask mask={inputMask} disabled={inProfile} />
              </Form.Item>
              <Form.Item
                initialValue={docs?.name}
                label={
                  <>
                    <span>
                      {translate(`${I18N_BASE_PATH}.formContent.form.columnName.label`)}{' '}
                    </span>
                    <i className="font-size-10 gx-font-italic" style={{ marginLeft: 2 }}>
                      -{' '}
                      {translate(
                        `${I18N_ALT_PATH}.formContent.form.optional` // => Opcional
                      )}
                    </i>
                  </>
                }
                name="name"
                rules={[{ required: false, defaultField: '' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                initialValue={docs?.reason}
                label={
                  <>
                    <span>
                      {translate(`${I18N_BASE_PATH}.formContent.form.columnReason.label`)}{' '}
                      <i
                        className="font-size-10 gx-font-italic"
                        style={{ marginLeft: 2 }}
                      >
                        -{' '}
                        {translate(
                          `${I18N_ALT_PATH}.formContent.form.optional` // => Opcional
                        )}
                      </i>
                    </span>
                  </>
                }
                name="reason"
                rules={[{ required: false, defaultField: '' }]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Col>

          <Col span={24} className="flex end">
            <Button
              type="button"
              htmlType="button"
              onClick={closeModal}
              // disabled={!fileUrl}
            >
              {translate(`${I18N_BASE_PATH}.formContent.buttons.cancel`)}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {translate(`${I18N_BASE_PATH}.formContent.buttons.confirm`)}
            </Button>
          </Col>
        </Row>
      ),
    [
      page,
      inProfile,
      docs,
      translate,
      isEdit,
      form,
      identificationMask,
      inputMask,
      closeModal,
      handleSubmit,
      loading,
    ]
  );

  const RestrictionCreateModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={589}
      >
        {formContent}
      </Modal>
    ),
    [modalVisible, closeModal, formContent]
  );

  return { openModal, closeModal, RestrictionCreateModal };
};

export default RestrictionCreate;
